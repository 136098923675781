import React from 'react';

const Content: React.FC<any> = (props: any) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default Content;