import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    bannerComponent: {
      position: "relative",
    },

    boxImageBanner: {
      filter: "blur(0)",
      transition: "filter .3s",
    },

    imageBanner: {
      width: "100%",
      background: "#59177a",
      height: "calc(94vh)",
      objectFit: "cover",
      objectPosition: "top",
      [theme.breakpoints.down("md")]: {
        height: "unset",
        marginTop: 80,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 68,
      },
    },

    bannerFeetWrapper: {
      width: "100%",
      position: "absolute",
      bottom: -53,
      left: 0,
      textAlign: "center",

      [theme.breakpoints.down("md")]: {
        bottom: -37,
      },

      [theme.breakpoints.down("sm")]: {
        bottom: -20,
      },

      "&:after": {
        content: "''",
        display: "block",
        position: "absolute",
        width: "100%",
        height: 18,
        background: "url(/images/buttons/after_btn.png)",
        top: "50%",
        backgroundRepeatX: "repeat",
        backgroundSize: "auto 100%",
        marginTop: 6,

        [theme.breakpoints.down("md")]: {
          height: 9,
        },

        [theme.breakpoints.down("sm")]: {
          height: 7,
          top: "44%",
        },
      }
    },

    btnFeetWrapper: {
      width: "100%",
      objectFit: "contain",
    },

    btnPlay: {
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      color: theme?.custom?.colors?.white,
      textTransform: "unset",
      background: "url(/images/buttons/btn_play2.png)",
      backgroundSize: "100% 100%",
      boxShadow: "none !important",
      width: 490,
      maxWidth: "100%",
      height: 140,
      transition: "all .1s ease-in",
      fontSize: 63,
      paddingTop: 36,
      position: "relative",
      zIndex: 2,

      [theme.breakpoints.down("md")]: {
        fontSize: 40,
        height: 98,
        width: 343,
        paddingTop: 28,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 30,
        height: 60,
        width: 200,
        paddingTop: 20,
      },

      "& .MuiTouchRipple-root": {
        display: "none",
      },

      "&:hover": {
        textShadow: "0 0 1.5rem #ffe788",
        transition: "text-shadow .2s linear",
        backgroundColor: "transparent",

        "& .MuiButton-label": {
          transform: "translateY(1px)",
        },
      },
    },
  };
});

export default useStyles;
