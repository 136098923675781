import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    marketPage: {
      minHeight:'70vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '100px 80px',
      position: 'relative',
      alignItems: 'center',
    },

    messagePopup: {
      display: 'flex',
      background: "url('/images/market/frame.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "top center",
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '100px 80px',
      position: 'relative',
      alignItems: 'center',
    },
  };
});

export default useStyles;