import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    bgFooter: {
      position: "relative",
      overflow: "hidden",
      /* background: "url('/images/footer/bg2.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "top center",*/
      marginTop: -2,
      padding: "50px 0",
    },

    footerComponent: {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: 1000,
      marginRight: "auto",
      marginLeft: "auto",

      [theme.breakpoints.down("md")]: {
        padding: "0 7%",
      },
    },

    logoFooter: {
      flex: "0 0 auto",
      width: "33.33333333%",

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    nameNavFooter: {
      fontSize: 30,
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      color: theme?.custom?.colors?.white,
      marginTop: 0,
      marginBottom: 15,

      "&:after": {
        display: "block",
        width: 30,
        height: 3,
        content: "''",
        backgroundColor: "#a400ff",
        borderRadius: 10,
        position: "relative",
        marginTop: 10,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },

    itemNavSocial: {
      display: "inline-block",
      width: 40,
      marginRight: 5,
      marginBottom: "1.5rem",

      "& img": {
        width: "100%",
        borderRadius: 10,
      },
    },

    logoWebmFooter: {
      width: "66%",
      top: 0,

      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    iconFooter: {
      position: "absolute",
      bottom: "17%",
      right: "2%",

      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    contentFooter: {
      display: "flex",
      flex: "0 0 auto",
      width: "66.66666667%",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    navFooter: {
      display: "flex",
      flex: "0 0 auto",
      width: "33.33333333%",
      flexDirection: "column",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    navFooterMobile: {
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },

    itemNav: {
      fontSize: 17,
      color: theme?.custom?.colors?.white,
      marginBottom: 25,
      cursor: "pointer",
      transition: "all .1s ease-in",

      "& a": {
        color: theme?.custom?.colors?.white,
        transition: "all .1s ease-in",

        "&:hover": {
          textShadow: "0 0 1.5rem #ff00fc",
          transform: "translateY(1px)",
        },
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        marginBottom: 15,
      },
    },

    groupSendEmail: {
      position: "relative",
      border: `2px solid ${theme?.custom?.colors?.white}`,
      borderRadius: 15,
    },

    txtEmail: {
      position: "absolute",
      top: 0,
      left: 0,
      width: 140,
      height: "100%",
      borderRight: `2px solid ${theme?.custom?.colors?.white}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 36,
      color: theme?.custom?.colors?.white,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,
    },

    inputSendEmail: {
      height: 63,
      width: "100%",
      border: "none",
      outline: "none",
      boxShadow: "none",
      background: "transparent",
      paddingLeft: 180,
      paddingRight: 65,
      fontSize: 36,
      color: theme?.custom?.colors?.white,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,

      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
        fontSize: 26,
      },
    },

    btnSendEmail: {
      position: "absolute",
      top: 0,
      right: 0,
      width: 63,
      height: "100%",
      boxShadow: "none",
      background: "transparent",
    },

    iconSendEmail: {
      fontSize: 36,
      color: theme?.custom?.colors?.white,
    },
  };
});

export default useStyles;
