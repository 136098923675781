import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorPage from './routing/ErrorPage';
import NotFoundPage from './routing/NotFoundPage';
import ErrorBoundary from './components/Base/ErrorBoundary';
import LandingPage from './routing/LandingPage';
import BrandPage from './routing/BrandPage';
import PlayPage from './routing/PlayPage';
import MarketPage from './routing/MarketPage';

/**
 * Main App routes.
 */
const routes = () => (
  <div>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/brand" component={BrandPage} />
      <Route path="/error" component={ErrorPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </div>
);

const routing = function createRouting() {
  if (window.location.host.split(".")[0] === "alpha") {
    return (
      <Router>
        <PlayPage />
      </Router>
    );
  } else if (window.location.host.split(".")[0] === "market") {
    return (
      <Router>
        <MarketPage />
      </Router>
    );
  } else {
    return (
      <Router>
        <ErrorBoundary>{routes()}</ErrorBoundary>
      </Router>
    );
  }
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;