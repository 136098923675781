import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    downloadComponent: {
      position: "relative",
      paddingBottom: 60,

      [theme.breakpoints.down("md")]: {
        paddingBottom: 60,
      },

      [theme.breakpoints.down("sm")]: {
        paddingBottom: 30,
      },
    },

    logoFull: {
      textAlign: "center",

      "& img": {
        width: 515,
        marginTop: "6vh",
        maxWidth: "50%",
      },
    },

    logoWebm: {
      width: "28%",
      marginTop: "4%",
      marginBottom: "1%",
      bottom: 0,

      [theme.breakpoints.down("sm")]: {
        width: "60%",
      },
    },

    desG: {
      fontSize: 21,
      color: theme?.custom?.colors?.txt,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,
      margin: "auto",
      marginTop: 55,
      width: 1280,
      maxWidth: "100%",
      textAlign: "center",
      lineHeight: "27px",

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "20px",
        paddingLeft: 15,
        paddingRight: 15,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        marginTop: 30,
      },
    },

    listBtn: {
      display: "flex",
      justifyContent: "space-between",
      width: 1000,
      maxWidth: "66%",
      margin: "auto",
      marginTop: 50,

      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: 50,
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
      },
    },

    btnG: {
      color: theme?.custom?.colors?.btn,
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      height: 65,
      padding: "5px 32px",
      width: 400,
      maxWidth: "100%",
      textTransform: "inherit",
      margin: 5,
      backgroundRepeat: "no-repeat",
      transition: "all .1s ease-in",

      [theme.breakpoints.down("md")]: {
        fontSize: 21,
        width: 290,
        height: 50,
      },

      [theme.breakpoints.down("sm")]: {
        margin: 5,
        fontSize: 15,
        width: 280,
        height: 43,
      },

      "& .MuiTouchRipple-root": {
        display: "none",
      },

      "&:hover": {
        transform: "translateY(1px)",
      },
    },

    boxVideo: {
      margin: "0 auto",
      width: "66%",
      background: "url(/images/bg_video.png) no-repeat 50%",
      backgroundSize: "contain",
      marginTop: 107,

      [theme.breakpoints.down("md")]: {
        marginTop: 50,
      },

      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },

    video: {
      position: "relative",
      overflow: "hidden",
      width: "100% !important",
      height: "100% !important",

      "& > div": {
        overflow: "hidden",
        paddingTop: "56.25%",
        position: "relative",
        width: "100% !important",
      },

      "& iframe": {
        inset: 0,
        height: "100%",
        position: "absolute",
        width: "100%",
        padding: "3vw 5vw",
      },
    },
  };
});

export default useStyles;
