import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useState } from 'react';

import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';

import SwiperCore, {
  Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

const FeaturesComponent = (props: any) => {
  const styles = useStyles();
  const [features] = useState([
    {
      name: "The Merge Master",
      describe: "You are officially appointed as The Merge Master.<br/>New items, new maps, new creatures, new rewards always appear, waiting to be matched, merged, combined, and built.<br/>It's your magical world, drag, merge, and organize the pieces the way you prefer. Your strategy is key, so think wisely.<br/>",
      img: "/images/features/a1.png",
    },
    {
      name: "Play to Earn",
      describe: "Metamerge is not just a game, it's a real economy.<br/>You can earn MTM and MWA tokens and NFTs, keeping them as valuable assets. By completing Daily Quest, mining, participating in the Social community, winning battles, the assets can be earned and traded via your own choices.<br/>",
      img: "/images/features/a2.png",
    },
    {
      name: "Trade It Your Way",
      describe: "All in-game assets are yours and you can trade freely on Metamerge Marketplace.",
      img: "/images/features/a3.png",
    },
    {
      name: "The Community",
      describe: "Metamerge player community where you can interact and socialize with your friends.<br/>Visit your friends' islands and make the decision to help or to steal from them. Do they forget to harvest the newly-ready crops? Well, they are yours now.",
      img: "/images/features/a4.png",
    },
    {
      name: "The Happy Uncertainty",
      describe: "It's not fun 100% guaranteed. Your magical merge is sometimes hidden with surprises, experience it yourself through the journey.",
      img: "/images/features/a5.png",
    },
    {
      name: "Metaverse",
      describe: "Metamerge is a Metaverse universe that connects us through a virtual world. Socializing with friends, buying your properties and services, attending events? They will soon be ready on our Metaverse system.",
      img: "/images/features/a6.png",
    }
  ])

  return (
    <div className={styles.featuresComponent}>
      <img className={styles.bgFeaturesComponent} src="/images/features/bg5.png" alt="/images/features/bg5.png" />
      <TitleLanding title="FEATURES" />

      <div className={styles.boxSlide}>
        <div className={styles.mySwiperFeatures}>
          <div className={styles.boxFeaturesSlider}>
            <ul className={styles.listFeature}>
              {
                features?.map((item, index) => {
                  return (
                    <li key={index} className={`${styles.itemFeature} ${index % 2 ? styles.itemFeatureAreas : ""}`}>
                      <div className={styles.imgFeature}>
                        <img src={item.img} alt={item.img} />
                      </div>
                      <div className={styles.infoFeature}>
                        <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                        <span dangerouslySetInnerHTML={{ __html: item.describe }}></span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesComponent;