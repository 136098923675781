import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    notFoundPage: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '100px 80px',
      position: 'relative',
      alignItems: 'center',
    },
  };
});

export default useStyles;