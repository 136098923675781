import { Button } from '@material-ui/core';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import { isIOS, isSafari } from 'react-device-detect';
import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';
import { Skeleton } from '@material-ui/lab';

const DownloadComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();

  return (
    <div className={styles.downloadComponent}>
      <div className={styles.logoFull}>
        {
          loading ?
            <Skeleton variant="rect" className={styles.logoWebm}>
              <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            : (
              (isIOS || isSafari) ?
                <img src="/logo_high.png" alt="/logo_high.png" className={styles.logoWebm} />
                :
                <video src="/webms/logo.webm" autoPlay muted loop className={styles.logoWebm} />
            )
        }
      </div>

      {/* <TitleLanding title="PLAY" />
      <ScrollAnimation animateIn='fadeIn'>
        <div className={styles.desG}>
          We are now on Google Play and Apple Store!
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn='fadeIn' className={styles.listBtn}>
          <a href="https://play.google.com/store/apps/details?id=nft.metaverse.metamerge" target="_blank"  className={styles.btnG}>
                  <img src="/images/buttons/btn_Googleplay.png" alt=""/>
          </a>
          <a href="https://apps.apple.com/app/metamerge/id1634440247" target="_blank"  className={styles.btnG}>
              <img src="/images/buttons/btn_appstore.png" alt=""/>
          </a>
          <a href="https://apps.apple.com/app/metamerge/id1634440247" target="_blank"  className={styles.btnG}>
              <img src="/images/buttons/btn_macm1.png" alt=""/>
          </a>
      </ScrollAnimation> */}
    </div>
  );
};

export default DownloadComponent;