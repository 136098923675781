import { Link } from 'react-router-dom';
import useStyles from './styles';

const FontsComponent = (props: any) => {
  const styles = useStyles();

  return (
    <section className={styles.fontsComponent}>
      <div className={styles.contentWidth}>
        <div className={styles.leftComponent}>
          <h2 className={styles.title}>Fonts</h2>
        </div>
        <div className={styles.rightComponent}>
          <div className={styles.fontB}>Caniste - Semibold</div>
          <a target="_blank" href="https://www.myfonts.com/fonts/ilham-herry/caniste/" className={styles.btnView}>
            <div style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }} className="kt-svg-icon-list-single kt-svg-icon-list-single-fe_arrowRightCircle">
              <svg 
                style={{display: "inline-block",verticalAlign: "middle", marginRight: 5,}} 
                viewBox="0 0 24 24" 
                height="24" 
                width="24" 
                fill="none" 
                stroke="currentColor" xmlns="http://www.w3.org/2000/svg" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                aria-hidden="true">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 16 16 12 12 8"></polyline>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              <span>Caniste Typeface (MyFonts)</span>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FontsComponent;