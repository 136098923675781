import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    conceptArtComponent: {
      position: "relative",
      paddingTop: 120,

      [theme.breakpoints.down("md")]: {
        paddingTop: 60,
      },
    },

    desConceptArt: {
      fontSize: 21,
      color: theme?.custom?.colors?.txt,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,
      margin: "auto",
      marginTop: 70,
      width: 1280,
      maxWidth: "100%",
      textAlign: "center",
      lineHeight: "27px",
      marginBottom: 135,

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "20px",
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 150,
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        fontSize: 14,
        marginBottom: 100,
      },
    },

    boxPicture: {
      width: "42%",
      margin: "auto",
      zIndex: 1,
      position: "relative",

      "&.shadow:after": {
        content: "''",
        display: "block",
        width: "calc(100% - 30px)",
        height: "calc(100% - 30px)",
        position: "absolute",
        top: 15,
        left: 15,
        boxShadow: "0px 0px 50px #c79940",
        borderRadius: 112,
        zIndex: -1,
      },

      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },

    boxSubPicture: {
      background: "url('/images/characters/bg_picture.png')",
      backgroundSize: "100% 100%",
      padding: 30,
      position: "relative",
      zIndex: 1,
    },

    boxSubPicture2: {
      position: "absolute",
      top: "0",
      left: 0,
      width: "100%",
      height: "100%",
    },

    imagePicture: {
      position: "relative",
    },

    image: {
      width: "100%",
    },

    imageCharacter: {
      position: "absolute",
      left: 35,
      top: "0%",
      width: "30%",
      transform: "translate(140%, 15%)",

      [theme.breakpoints.down("md")]: {
        width: "50%",
        transform: "translate(60%, 0%)",
      },
    },

    imageCharacter2: {
      position: "absolute",
      left: 35,
      top: "-2%",
      width: "20%",
      transform: "translate(240%, 15%)",
    },

    namePicture: {
      fontSize: 40.27,
      color: theme?.custom?.colors?.picture,
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      textAlign: "center",
      marginTop: 40,
      marginBottom: 5,

      [theme.breakpoints.down("sm")]: {
        fontSize: 25,
      },
    },

    charSwiper: {
      width: "100%",
      maxWidth: "100%",
      margin: "auto",
      marginTop: 30,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",

      "& .swiper-button-prev": {
        background: "url('/images/buttons/btn_preview.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        transition: "all .1s ease-in",
        left: "10%",
        opacity: 0.5,
        marginTop: "-3%",

        [theme.breakpoints.down("md")]: {
          left: "2%",
          width: 35,
          height: 45,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(1px)",
          opacity: 1,
        },
      },

      "& .swiper-button-next": {
        background: "url('/images/buttons/btn_next.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        transition: "all .1s ease-in",
        right: "10%",
        opacity: 0.5,
        marginTop: "-3%",

        [theme.breakpoints.down("md")]: {
          right: "2%",
          width: 35,
          height: 45,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(2px)",
          opacity: 1,
        },
      },
    },

    charImageSlider: {
      textAlign: "center",
      position: "relative",
    },

    bgImageSlider2: {
      position: "relative",
      maxWidth: "50%",

      [theme.breakpoints.down("sm")]: {
        maxWidth: "72%",
      },
    },

    boxSliderUni: {
      width: "1320px",
      maxWidth: "96%",
      margin: "auto",
      position: "relative",

      "& .swiper-slide-active": {
        "& > div": {
          transform: "scale(1.2, 1.2)",
          opacity: 1
        },
      },

      [theme.breakpoints.up("lg")]: {
        paddingLeft: 110,
        paddingRight: 110,
      },
    },

    mySwiper: {
      width: "100%",
      marginTop: 100,
      marginBottom: 150,
      paddingTop: 60,
      paddingBottom: 60,
      position: "unset",
      // background: "url('/images/characters/bg.png')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      // paddingBottom: 585,

      [theme.breakpoints.down("md")]: {
        marginBottom: 150,
      },

      [theme.breakpoints.down("sm")]: {
        marginBottom: 100,
      },

      "& .swiper-button-prev": {
        background: "url('/images/buttons/btn_preview.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        transition: "all .1s ease-in",
        left: "10%",
        opacity: 0.5,
        marginTop: "-3%",

        [theme.breakpoints.up("md")]: {
          left: 0,
        },

        [theme.breakpoints.down("md")]: {
          left: "2%",
          width: 35,
          height: 45,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(1px)",
          opacity: 1,
        },
      },

      "& .swiper-button-next": {
        background: "url('/images/buttons/btn_next.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        transition: "all .1s ease-in",
        right: "10%",
        opacity: 0.5,
        marginTop: "-3%",

        [theme.breakpoints.up("md")]: {
          right: 0,
        },

        [theme.breakpoints.down("md")]: {
          right: "2%",
          width: 35,
          height: 45,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(2px)",
          opacity: 1,
        },
      },
    },

    boxImageSlider: {
      textAlign: "center",
      width: 470,
      zIndex: 2,
      position: "relative",
      maxWidth: "95%",
      minWidth: 150,
      margin: "auto",
      opacity: 0.5,

      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
    },

    bgImageSlider: {
      width: "100%",
      height: "100%",

      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
    },

    bgImageSlider3: {},

    imageSlider: {
      width: "100%",
      position: "relative",
      zIndex: 3,
      marginBottom: "40%",
      transform: "translate(0%, 10%)",

      [theme.breakpoints.down("sm")]: {
        width: "70%",
        transform: "translate(0%, 25%)",
      },
    },
    imageSlider3: {
      width: "130%",
      position: "relative",
      zIndex: 3,
      marginBottom: "40%",
      transform: "translate(-9%, 10%)",

      [theme.breakpoints.down("sm")]: {
        width: "90%",
        transform: "translate(2%, 25%)",
      },
    },

    imageSlider4: {
      width: "100%",
      position: "relative",
      zIndex: 3,
      marginBottom: "40%",
      transform: "translate(0%, 10%)",

      [theme.breakpoints.down("sm")]: {
        width: "70%",
        transform: "translate(0%, 35%)",
      },
    },

    imageSlider2: {
      position: "absolute",
      left: "5%",
      top: "100%",
      width: "35%",
      transform: "translate(85%, 25%)",
      zIndex: 2,

      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },

    charName: {
      fontSize: 22,
      color: theme?.custom?.colors?.title,
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      textAlign: "center",
      marginTop: 40,
      marginBottom: 10,
      width: "100%",
      zIndex: 3,
      position: "absolute",
      bottom: 0,

      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
      },

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
        marginBottom: 7,
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        marginBottom: 8,
      },
    },
  };
});

export default useStyles;
