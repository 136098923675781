import useStyles from './styles';

const nav = ["Light BG", "Dark BG", "Red BG"];

const ColorComponent = (props: any) => {
  const styles = useStyles();

  return (
    <section className={styles.colorComponent}>
      <div className={styles.contentWidth}>
        <div className={styles.leftComponent}>
          <h2 className={styles.title}>Color</h2>
        </div>
        <div className={styles.rightComponent}>
          <h5 className={styles.titleRight}>Primary</h5>
          <div className={styles.contentTab}>
          <div className={styles.listCardColor} style={{minHeight: 200}}>
              <div className={styles.cardColor} style={{background: "#22185e"}}>
                <p className={styles.desNameCard} style={{color: "#FFFFFF"}}>#22185e</p>
              </div>
              <div className={styles.cardColor} style={{background: "#d122aa"}}>
                <p className={styles.desNameCard} style={{color: "#FFFFFF"}}>#d122aa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ColorComponent;