
import { useEffect, useState } from 'react';
import { AppProvider } from './store';
import createRoutes from './routes';
import defaultTheme from './thems/DefaultTheme/DefaultTheme';
import { ThemeProvider, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  progress: {
    color: '#4f146d',
  }
}));

const App = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener('load', (event) => {
      setIsLoadingPage(false)
    });
   
    const timeout = window.setTimeout(() => {
      if(!!isLoadingPage) {
        setIsLoadingPage(false)
      }
    }, 3000);

    return () => window.clearTimeout(timeout); 
  }, [])

  return (
    <AppProvider>
      <Backdrop className={classes.backdrop} open={isLoadingPage}>
        <CircularProgress className={classes.progress} />
      </Backdrop>
      <ThemeProvider theme={defaultTheme}>
        {createRoutes()}
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;