import useStyles from './styles';
import { isIOS, isSafari } from 'react-device-detect';

const BannerBrandComponent = (props: any) => {
  const styles = useStyles();

  return (
    <section className={styles.bannerBrandComponent}>
      <div className={styles.contentWidth}>
        <div className={styles.kadenceColumn}>
            {
                (isIOS || isSafari) ?
                    <img src="/logo_high.png" alt="/logo_high.png" className={styles.logoWebm} />
                    :
                    <video src="/webms/logo.webm" autoPlay muted loop className={styles.logoWebm} />
            }
          <h2 className={styles.title}>MetaMerge Brand Assets</h2>
        </div>
      </div>
    </section>
  );
};

export default BannerBrandComponent;