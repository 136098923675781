import React, { useEffect, useState } from 'react';
import Header from '../../Base/Header';
import Content from '../../Base/Content';
import useStyles from './styles';

const DefaultLayout = (props: any) => {
  const { onGotoSection } = props;
  const styles = useStyles();
  const [gotoSection, getGotoSection] = useState('');

  useEffect(() => {
    onGotoSection(gotoSection);
  }, [gotoSection, onGotoSection, props])

  return (
    <div className={styles.overMobile + " " + styles.mainContent}>
      <Header onGotoSection={(value: string) => getGotoSection(value)} />
      <main>
        <Content gotoSection={gotoSection}>{props.children}</Content>
      </main>
    </div>
  );
};

export default DefaultLayout;