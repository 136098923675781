import {useState} from 'react';
import {isIOS, isSafari} from 'react-device-detect';
import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';

const RoadMapComponent = (props: any) => {
    const styles = useStyles();
    const [roadMaps] = useState([
        {
            name: "Apr 2021:<br/>Plant The Seed",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Market research",
                        "Game Development"
                    ]
                }
            ]
        },
        {
            name: "Q4 2021:<br/>The Germination",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Game Trailer",
                        "Pitch Deck",
                        "Website release",
                        "PR Collaborations",
                        "Alpha Testing Release"
                    ]
                }
            ]
        },
        {
            name: "Q1 2022:<br/>The Bud",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Community Growth",
                        "Fund Raising",
                        "Partnership development",
                        "Closed Beta Release (Merge to earn tokens, Free to earn NFTs, Quest to earn,...)",
                    ]
                }
            ]
        },
        {
            name: "Q2 2022:<br/>The Blossom",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Giveaways/ Bounties",
                        "Open Beta and Testnet",
                    ]
                },
            ]
        },
        {
            name: "Q3 2022:<br/>The Fruit",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Leaderboard",
                        "More decors, more legend animals",
                        "Island map extension",
                        "Social interaction in game (Visit, Give gifts , Steal tokens)",
                        "Game Mainnet Release",
                        "Battle Mode Internal Testing",
                    ]
                },
            ]
        },
        {
            name: "Q4 2022:<br/>The Ripening",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "IDO",
                        "Gaming Guilds integration",
                        "CEX listing",
                        "DEX listing",
                        "Onchain NFT Marketplace",
                        "Battle Mode Official Release",
                        "Cross metaverse with other games",
                    ]
                },
            ]
        },
        {
            name: "2023:<br/>The Forest",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Gaming events",
                        "Tournaments",
                        "3D transformation",
                        "AR/VR experiments",
                    ]
                },
            ]
        },
        {
            name: "2024:<br/>Beyond The Border",
            childs: [
                {
                    time: '',
                    des: "",
                    list: [
                        "Official AR/VR game release",
                    ]
                },
            ]
        },
    ])


    return (
        <div className={styles.roadMapComponent}>
            {
                (isIOS || isSafari) ?
                    <img src="/images/road_map/bg3.png" alt="/images/road_map/bg3.png" className={styles.bgWebm1Img}/>
                    :
                    <video src="/webms/roadmap1.webm" autoPlay muted loop className={styles.bgWebm1}/>
            }

            {
                (isIOS || isSafari) ?
                    <img src="/images/road_map/bg4.png" alt="/images/road_map/bg4.png"
                         className={styles.bgWebmroadMap2}/>
                    :
                    <video src="/webms/roadmap2.webm" autoPlay muted loop className={styles.bgWebmroadMap2}/>
            }

            <img className={styles.bgRoadMapComponent} src="/images/road_map/bg1.png" alt="/images/road_map/bg1.png"/>
            <img className={styles.bgRoadMapComponent2} src="/images/road_map/bg2.png" alt="/images/road_map/bg2.png"/>
            <TitleLanding title="ROADMAP"/>
            <div className={styles.contentRoadMap}>
                <ul className={styles.listRoadMap}>
                    {
                        roadMaps?.map((item, index) => {
                            return (
                                <li key={index}
                                    className={`${styles.itemRoadMap} ${index % 2 ? styles.itemRoadMapOll : ""}`}>
                                    <h3 dangerouslySetInnerHTML={{__html: item.name}}
                                        className={styles.nameRoadMap}></h3>
                                    {item?.childs?.map((child, i) => {
                                        return (
                                            <div key={i} className={styles.infoRoadMap}>
                                                {/*<span dangerouslySetInnerHTML={{ __html: child.time }} className={styles.time}></span>
                        <span dangerouslySetInnerHTML={{ __html: child.des }} className={styles.des}></span>*/}
                                                {
                                                    child?.list?.length < 1 && <><br/><br/></>
                                                }
                                                {child?.list?.map((li, il) => {
                                                    return (
                                                        <div key={il} dangerouslySetInnerHTML={{__html: li}}
                                                             className={styles.itemList}></div>
                                                    )
                                                })
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className={styles.khoi}>
                {
                    (isIOS || isSafari) ?
                        <img src="/images/road_map/bg5.png" alt="/images/road_map/bg5.png"
                             className={styles.bgWebmKhoiImg}/>
                        :
                        <video src="/webms/oil_cauldron.webm" autoPlay muted loop className={styles.bgWebmKhoi}/>
                }
            </div>
        </div>
    );
};

export default RoadMapComponent;