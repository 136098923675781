import useStyles from './styles';
import {isIOS, isSafari} from "react-device-detect";

const TitleLanding = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.center + " " + props.className}>
      <h2 className={styles.titleLanding}>
          {
              (isIOS || isSafari) ?
                  <img src="/images/titles/top_title.png" alt="/images/titles/top_title.png" className={styles.bgImg} />
                  :
                  <video src="/webms/title_bg.webm" autoPlay muted loop className={styles.bgWebm} />
          }
        <span className={styles.titleEffect}>{props.title}</span>
      </h2>
    </div>
  );
};

export default TitleLanding;