import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        colorComponent: {
            position: "relative",
            backgroundColor: "#FFFFFF",
        },

        contentWidth: {
            maxWidth: 1290,
            paddingTop: 100,
            paddingBottom: 100,
            paddingLeft: 15,
            paddingRight: 15,
            margin: "auto",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 10,
            flex: "1",

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                paddingTop: 30,
                paddingBottom: 30,
            },
        },

        leftComponent: {
            marginRight: 35,
            flex: "0 1 25%",

            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                flex: "1",
            },
        },

        title: {
            marginTop: 0,
            marginBottom: "1.5rem",
            fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
            fontSize: 40,
            color: "#59177a",

            [theme.breakpoints.down("sm")]: {
                fontSize: 26,
            },
        },

        destitle: {
            fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
            fontSize: 16,
            color: "#59177a",
        },

        rightComponent: {
            flex: "0 1 100%",
        },

        titleRight: {
            display: "inline-block",
            margin: 0,
            marginLeft: 25,
            zIndex: 4,
            position: "relative",
            fontSize: 18,
            lineHeight: "1.4em",
            padding: "10px 20px 10px 20px",
            cursor: "pointer",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            color: "#81878f",
            background: "#ECE9F0",
        },

        titleRight2: {
            marginTop: "2.5rem",
        },

        listCardColor: {
            display: "flex",
            flexWrap: "nowrap",
            position: "relative",
            zIndex: 10,

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },

        cardColor: {
            padding: 20,
            display: "flex",
            flexDirection: "column",
            zIndex: 1,
            minWidth: 0,
            minHeight: 0,
            height: 238,
            alignSelf: "auto",
            marginRight: 30,
            flex: "0 1 50%",
            borderRadius: 3,
            border: "1px solid #e2e6e6",
            justifyContent: "center",
            alignItems: "center",

            "&:last-child": {
                marginRight: 0,
            },

            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                marginBottom: 10,
            },
        },

        nameCard: {
            marginTop: 0,
            marginBottom: 10,
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            fontSize: 30
        },

        nameCard2: {
            marginTop: 0,
            marginBottom: 10,
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            fontSize: 17
        },

        desNameCard: {
            marginTop: 0,
            marginBottom: 0,
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            fontSize: 16
        },

        contentTab: {
            background: "#ECE9F0",
            padding: 50,
            display: "block",
            borderRadius: 8,
            width: 900,
            maxWidth: "100%",
        },

    };
});

export default useStyles;
