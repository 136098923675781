import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    partnersComponent: {
      position: "relative",
      paddingTop: "12%",
      background: "url('/images/partners/bg1.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "top center",
      paddingBottom: "15%",

      [theme.breakpoints.down("sm")]: {
        paddingBottom: "33%",
        paddingTop: "32%",
      },
    },

    listPartners: {
      width: 1265,
      padding: "0px 10px",
      maxWidth: "100%",
      margin: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 70,
    },

    boxPartner: {
      minHeight: 135,
      // background: "url('/images/partners/bg.png')",
      backgroundSize: "100% 100%",
      width: 290,
      maxWidth: "100%",
      margin: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        margin: "auto",
      },
    },

    imgPartner: {
      maxWidth: "90%",
      maxHeight: "90%",
    },

    partnerAll: {
      width: "calc(100% - 50px)",
      marginLeft: 25,
      marginTop: 25,
    },

    titlePartners: {

      [theme.breakpoints.down("sm")]: {
        marginTop: "-15%",
      },
    },

    boxSlider: {
      width: 1575,
      maxWidth: "100%",
      padding: "0px 10%",
      margin: "auto",
      position: "relative",
      marginTop: 70,

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        marginBottom: 30,
      },
    },

    sliderPartnersComponent: {
      width: "100%",
      maxWidth: "100%",
      margin: "auto",
      marginTop: 20,
      position: "initial",

      [theme.breakpoints.down("xs")]: {
        width: "75%",
      },

      "& .swiper-button-prev": {
        background: "url('/images/buttons/btn_preview.png')",
        backgroundSize: "100% 100%",
        width: 62,
        height: 82,
        transition: "all .1s ease-in",
        left: 0,
        marginTop: "-4%",
        opacity: 0.5,

        [theme.breakpoints.down("xs")]: {
          marginTop: "-8%",
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(1px)",
          opacity: 1,
        },
      },

      "& .swiper-button-next": {
        background: "url('/images/buttons/btn_next.png')",
        backgroundSize: "100% 100%",
        width: 62,
        height: 82,
        transition: "all .1s ease-in",
        right: 0,
        marginTop: "-4%",
        opacity: 0.5,

        [theme.breakpoints.down("xs")]: {
          marginTop: "-8%",
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(2px)",
          opacity: 1,
        },
      },
    },
  };
});

export default useStyles;
