import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    brandPage: {
      minHeight: '100vh',
    },

    btnGoTop: {
      position: "fixed",
      bottom: 15,
      right: 15,
      zIndex: 50,
      background: theme?.custom?.colors?.btnGoTop,
      opacity: 0.5,
      minWidth: "auto",
      width: 40,
      height: 40,

      "&:hover": {
        opacity: 0.9,
        background: theme?.custom?.colors?.titleShadow,
      },
    },

    iconGoTop: {
      color: theme?.custom?.colors?.white,
    },
  };
});

export default useStyles;