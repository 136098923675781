
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import FooterComponent from '../LandingPage/FooterComponent';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import useStyles from './styles';

const PlayPageLayout = (props: any) => {
  const styles = useStyles();
  const onGotoSection = (value: string) => { };

  const [openNotify, setOpenNotify] = useState<boolean>(false);
  const [titleNotify, setTitleNotify] = useState<string>("");
  const [typeNotify, setTypeNotify] = useState<any>("info");
  const [collapseNotify, setCollapseNotify] = useState<boolean>(false);
  const [contentCollapseNotify, setContentCollapseNotify] = useState<string>("");

  const notify = (type: string, title: string, content: string, openNotify: boolean) => {
    switch (type) {
      case "error":
        setOpenNotify(openNotify);
        setTitleNotify(title);
        setContentCollapseNotify(content);
        setTypeNotify("error");
        break;
      case "success":
        setOpenNotify(openNotify);
        setTitleNotify(title);
        setContentCollapseNotify(content);
        setTypeNotify("success");
        break;
      case "warning":
        setOpenNotify(openNotify);
        setTitleNotify(title);
        setContentCollapseNotify(content);
        setTypeNotify("warning");
        break;
      default:
        setOpenNotify(openNotify);
        setTitleNotify(title);
        setContentCollapseNotify(content);
        setTypeNotify("info");
    }

    if(openNotify) {
      setTimeout(() => {
        setOpenNotify(false);
        setCollapseNotify(false);
      }, 5000)
    }
  };

  return (
    <DefaultLayout onGotoSection={(value: string) => onGotoSection(value)}>
      <Alert severity={typeNotify} variant="filled" className={styles.alert} style={{display: openNotify ? "flex" : "none"}}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpenNotify(false)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <div
          onClick={() => {
            setCollapseNotify(!collapseNotify);
          }}
          className={styles.headNoti}
        >{titleNotify}</div>
        <Collapse in={collapseNotify}>
          {contentCollapseNotify}
        </Collapse>
      </Alert>

      <div style={{ textAlign: "center" }}>

        <div id="unity-container" className={`unity-desktop ${styles.unityDesktop}`}>
          {/* <CircularProgress style={{color: "#FFF"}} className={styles.iconLoading} /> */}
          <canvas id="unity-canvas" className={styles.unityCanvas}></canvas>
          <div id="unity-loading-bar">
            <div id="unity-logo"></div>
            <div id="unity-progress-bar-empty">
              <div id="unity-progress-bar-full"></div>
              {/* <div id="unity-progress-bar-text">25%</div> */}
            </div>
          </div>
          {/* <div id="unity-warning" ref={unityWarningRef}></div> */}
          <div id="unity-footer">
            {/* <div id="unity-webgl-logo"></div> */}
            <div id="unity-fullscreen-button"></div>
            {/* <div id="unity-build-title">MetaMerge</div> */}
          </div>
          <Helmet>
            <script>
              {`
              var container = document.querySelector("#unity-container");
              var canvas = document.querySelector("#unity-canvas");
              var loadingBar = document.querySelector("#unity-loading-bar");
              var progressBarFull = document.querySelector("#unity-progress-bar-full");
              var fullscreenButton = document.querySelector("#unity-fullscreen-button");
              var warningBanner = document.querySelector("#unity-warning");
    
              console.log(container)
    
              // Shows a temporary message banner/ribbon for a few seconds, or
              // a permanent error message on top of the canvas if type=='error'.
              // If type=='warning', a yellow highlight color is used.
              // Modify or remove this function to customize the visually presented
              // way that non-critical warnings and error messages are presented to the
              // user.
              function unityShowBanner(msg, type) {
                  function updateBannerVisibility() {
                      warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
                  }
    
                  var div = document.createElement('div');
                  div.innerHTML = msg;
                  warningBanner.appendChild(div);
                  if (type == 'error') div.style = 'background: red; padding: 10px;';
                  else {
                      if (type == 'warning') div.style = 'background: yellow; padding: 10px;';
                      setTimeout(function () {
                          warningBanner.removeChild(div);
                          updateBannerVisibility();
                      }, 5000);
                  }
                  updateBannerVisibility();
              }
    
              var buildUrl = "Build";
              var loaderUrl = buildUrl + "/Build.loader.js";
              var config = {
                  dataUrl: buildUrl + "/Build.data",
                  frameworkUrl: buildUrl + "/Build.framework.js",
                  codeUrl: buildUrl + "/Build.wasm",
                  streamingAssetsUrl: "StreamingAssets",
                  companyName: "MMStudio",
                  productName: "MetaMerge",
                  productVersion: "1.0.0",
              };
    
              // By default Unity keeps WebGL canvas render target size matched with
              // the DOM size of the canvas element (scaled by window.devicePixelRatio)
              // Set this to false if you want to decouple this synchronization from
              // happening inside the engine, and you would instead like to size up
              // the canvas DOM size and WebGL render target sizes yourself.
              // config.matchWebGLToCanvasSize = false;
    
              if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                  container.className = "unity-mobile";
                  // Avoid draining fillrate performance on mobile devices,
                  // and default/override low DPI mode on mobile browsers.
                  config.devicePixelRatio = 1;
                  // unityShowBanner('WebGL builds are not supported on mobile devices.');
                  notify("info", "This game are not supported on mobile devices!", "Mobile version coming soon!", false);
              } else {
                  canvas.style.width = "1440px";
                  canvas.style.height = "800px";
              }
              loadingBar.style.display = "block";
    
              var script = document.createElement("script");
              script.src = loaderUrl;
              script.onload = () => {
                  createUnityInstance(canvas, config, (progress) => {
                      progressBarFull.style.width = 100 * progress + "%";
                  }).then((unityInstance) => {
                      loadingBar.style.display = "none";
                      fullscreenButton.onclick = () => {
                          unityInstance.SetFullscreen(1);
                      };
                  }).catch((message) => {
                      notify("error", "Something went wrong!", message, false);
                  });
              };
              document.body.appendChild(script);
            `}
            </script>
          </Helmet>
        </div>
      </div>
      <FooterComponent />
    </DefaultLayout>
  );
};

export default PlayPageLayout;