import { Swiper, SwiperSlide } from "swiper/react";
import { isIOS, isSafari } from 'react-device-detect';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';

import SwiperCore, {
  Navigation
} from 'swiper';
import { Skeleton } from "@material-ui/lab";

SwiperCore.use([Navigation]);

const ConceptArtComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();

  return (
    <div className={styles.conceptArtComponent}>
      <TitleLanding title="CONCEPT ART" />
      <div className={styles.desConceptArt}>
        The concept art of MetaMerge focuses on the nature of the virtual Merge universe, stimulating the player's curiosity and satisfying their passion for discovery.
      </div>

      {/*<div className={styles.boxPicture} style={{ transform: "rotate(345deg)" }}>
        <div className={styles.boxSubPicture}>
          <div className={styles.imagePicture}>
            <img className={styles.image} src="/images/characters/picture1.png" alt="/images/characters/picture1.png" />
            {
              (isIOS || isSafari) ?
                <img className={styles.imageCharacter} src="/images/characters/nv_1.png" alt="/images/characters/nv_1.png" />
                :
                <video src="/webms/witch.webm" autoPlay muted loop className={styles.imageCharacter} />
            }
          </div>
          <h3 className={styles.namePicture}>Lucia</h3>
        </div>
      </div>

      <div className={`${styles.boxPicture} shadow`} style={{ transform: "rotate(5deg)", marginTop: -40 }}>
        <div className={styles.boxSubPicture}>
          <div className={styles.imagePicture}>
            <img className={styles.image} src="/images/characters/picture2.png" alt="/images/characters/picture2.png" />
          </div>
          <h3 className={styles.namePicture}>Grand Building</h3>
        </div>
      </div>*/}

      <Swiper
        navigation={true}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          "clickable": true
        }}
        className={styles.charSwiper}>
        <SwiperSlide className={styles.charImageSlider}>
          {
            loading ?
              <Skeleton variant="rect" className={styles.bgImageSlider2}>
                <div style={{ paddingTop: '57%' }} />
              </Skeleton>
              : <img className={styles.bgImageSlider2} src="/images/characters/3q.png" alt="/images/characters/3q.png" />
          }

          {
            loading ?
              <Skeleton variant="rect" className={styles.imageCharacter}>
                <div style={{ paddingTop: '57%' }} />
              </Skeleton>
              : (
                (isIOS || isSafari) ?
                  <img className={styles.imageCharacter} src="/images/characters/nv_1.png" alt="/images/characters/nv_1.png" />
                  :
                  <video src="/webms/witch.webm" autoPlay muted loop className={styles.imageCharacter} />
              )
          }
        </SwiperSlide>
        <SwiperSlide className={styles.charImageSlider}>
          {
            loading ?
              <Skeleton variant="rect" className={styles.bgImageSlider2}>
                <div style={{ paddingTop: '57%' }} />
              </Skeleton>
              : <img className={styles.bgImageSlider2} src="/images/characters/2q.png" alt="/images/characters/2q.png" />
          }
        </SwiperSlide>
        <SwiperSlide className={styles.charImageSlider}>
          {
            loading ?
              <Skeleton variant="rect" className={styles.bgImageSlider2}>
                <div style={{ paddingTop: '57%' }} />
              </Skeleton>
              : <img className={styles.bgImageSlider2} src="/images/characters/1q.png" alt="/images/characters/1q.png" />
          }
        </SwiperSlide>
      </Swiper>

      <div className={styles.boxSliderUni}>
        <Swiper
          centeredSlides={true}
          navigation={true}
          slidesPerView={3}
          spaceBetween={30}
          loop={true}
          pagination={{
            "clickable": true
          }}
          breakpoints={{
            "1": {
              "slidesPerView": 1,
              "spaceBetween": 20
            },
            "768": {
              "slidesPerView": 3,
              "spaceBetween": 30
            },
          }}
          className={styles.mySwiper}>
          <SwiperSlide>
            <div className={styles.boxImageSlider}>
              {
                (isIOS || isSafari) ?
                  <img className={styles.imageSlider4} src="/images/characters/Unicorn_1.png" alt="/images/characters/Unicorn_1.png" />
                  :
                  <video src="/webms/Unicorn_1.webm" autoPlay muted loop className={styles.imageSlider} />
              }
              <div className={styles.boxSubPicture2}>
                <img src="/images/characters/frame_char.png" alt="/images/characters/frame_char.png" className={styles.bgImageSlider} />
                <h3 className={styles.charName}>Water Unicorn</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.boxImageSlider}>
              {
                (isIOS || isSafari) ?
                  <img className={styles.imageSlider4} src="/images/characters/Unicorn_2.png" alt="/images/characters/Unicorn_2.png" />
                  :
                  <video src="/webms/Unicorn_2.webm" autoPlay muted loop className={styles.imageSlider3} />
              }
              <div className={styles.boxSubPicture2}>
                <img src="/images/characters/frame_char.png" alt="/images/characters/frame_char.png" className={styles.bgImageSlider} />
                <h3 className={styles.charName}>Stone Unicorn</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.boxImageSlider}>
              {
                (isIOS || isSafari) ?
                  <img className={styles.imageSlider4} src="/images/characters/Unicorn_3.png" alt="/images/characters/Unicorn_3.png" />
                  :
                  <video src="/webms/Unicorn_3.webm" autoPlay muted loop className={styles.imageSlider3} />
              }
              <div className={styles.boxSubPicture2}>
                <img src="/images/characters/frame_char.png" alt="/images/characters/frame_char.png" className={styles.bgImageSlider} />
                <h3 className={styles.charName}>Volcano Unicorn</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.boxImageSlider}>
              {
                (isIOS || isSafari) ?
                  <img className={styles.imageSlider4} src="/images/characters/Unicorn_4.png" alt="/images/characters/Unicorn_4.png" />
                  :
                  <video src="/webms/Unicorn_4.webm" autoPlay muted loop className={styles.imageSlider} />
              }
              <div className={styles.boxSubPicture2}>
                <img src="/images/characters/frame_char.png" alt="/images/characters/frame_char.png" className={styles.bgImageSlider} />
                <h3 className={styles.charName}>Metal Unicorn</h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.boxImageSlider}>
              {
                (isIOS || isSafari) ?
                  <img className={styles.imageSlider4} src="/images/characters/Unicorn_5.png" alt="/images/characters/Unicorn_5.png" />
                  :
                  <video src="/webms/Unicorn_5.webm" autoPlay muted loop className={styles.imageSlider} />
              }
              <div className={styles.boxSubPicture2}>
                <img src="/images/characters/frame_char.png" alt="/images/characters/frame_char.png" className={styles.bgImageSlider} />
                <h3 className={styles.charName}>Wood Unicorn</h3>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default ConceptArtComponent;