
import FooterComponent from '../LandingPage/FooterComponent';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import useStyles from './styles';
import Message from "../../components/Message";

const MarketPageLayout = (props: any) => {
  const styles = useStyles();
  const onGotoSection = (value: string) => { };

  return (
      <DefaultLayout onGotoSection={(value: string) => onGotoSection(value)}>
      <div className={styles.marketPage}>
          <Message title="COMING SOON!" />
      </div>
      <FooterComponent />
    </DefaultLayout>
  );
};

export default MarketPageLayout;