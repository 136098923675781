import {useCallback, useEffect, useState, forwardRef} from 'react';
import {Hidden, Dialog, DialogContent, Toolbar, AppBar, IconButton, Button, Slide} from '@material-ui/core';
import {TransitionProps} from '@material-ui/core/transitions';
import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {useLocation} from 'react-router-dom';
import useStyles from './styles';

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="right" ref={ref} {...props} />;
});


const menu = [
    {
        name: "Home",
        link: "HomeSection",
    },
    {
        name: "Story",
        link: "GameStoryComponent",
    },
    {
        name: "Features",
        link: "FeaturesComponent",
    },
    {
        name: "Roadmap",
        link: "RoadMapComponent",
    },
    {
        name: "Tokenomics",
        link: "TokenOmicsComponent",
    },
    /*{
        name: "Team",
        link: "OurTeamComponent",
    },*/
    {
        name: "News",
        link: "https://news.metamerge.game/",
    },
]

const menuPlay = [
    {
        name: "Home",
        link: process.env.REACT_APP_PUBLIC_DOMAIN,
    },
    {
        name: "Play",
        link: "#",
    },
    {
        name: "Marketplace",
        link: "https://market.metamerge.game",
    },
    {
        name: "Document",
        link: "https://docs.metamerge.game",
    },
]

const menuMarket = [
    {
        name: "Home",
        link: process.env.REACT_APP_PUBLIC_DOMAIN,
    },
    {
        name: "Play",
        link: "https://alpha.metamerge.game",
    },
    {
        name: "Marketplace",
        link: '#',
    },
    {
        name: "Document",
        link: "https://docs.metamerge.game",
    },
]

const Header = (props: any) => {
    const styles = useStyles();
    const location = useLocation();
    const [y, setY] = useState(window.scrollY);
    const [showMenu, setShowMenu] = useState(true);
    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const [isPlayPage, setIsPlayPage] = useState(false);
    const [isMarketPage, setIsMarketPage] = useState(false);

    const handleNavigation = useCallback((e) => {
        const window = e.currentTarget;
        if (y > window.scrollY) {
            setShowMenu(true);
        } else if (y < window.scrollY) {
            setShowMenu(false);
        }
        setY(window.scrollY);
    }, [y]);

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const handleCloseMenuMobile = () => {
        setOpenMenuMobile(false);
    };

    const onClickMenuMobile = (link: any) => {
        if (link.startsWith("http")) {
            onClickItemMenuPlay(link)
        } else {
            props.onGotoSection(link);
        }
        setOpenMenuMobile(false);
    };

    const onClickItemMenuPlay = (link: any) => {
        window.location.href = `${link}`;
    };

    useEffect(() => {
        if (window.location.host.split(".")[0] === "alpha") {
            setIsPlayPage(true)
            setIsMarketPage(false)
        } else if (window.location.host.split(".")[0] === "market") {
            setIsMarketPage(true)
            setIsPlayPage(false)
        } else if (location.pathname === "/brand") {
            setIsMarketPage(true)
            setIsPlayPage(false)
        } else {
            setIsPlayPage(false)
            setIsMarketPage(false)
        }
    }, [location])

    return (
        <header className={`${styles.mainHeader} ${showMenu ? styles.showMainHeader : styles.hiddenMainHeader}`}>
            <div className={styles.boxHeader}>
                <Hidden mdDown>
                    <ul className={styles.menuHeader}>
                        {isPlayPage ?
                            menuPlay?.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={styles.itemMenuHeader}
                                        onClick={() => onClickItemMenuPlay(item.link)}
                                    >
                                        {item?.name}
                                    </li>
                                )
                            })
                            :
                            isMarketPage ?
                                menuMarket?.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={styles.itemMenuHeader}
                                            onClick={() => onClickItemMenuPlay(item.link)}
                                        >
                                            {item?.name}
                                        </li>
                                    )
                                })
                                :
                                menu?.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={styles.itemMenuHeader}
                                            onClick={() => {
                                                item.link.startsWith("http") ? onClickItemMenuPlay(item.link) : props.onGotoSection(item.link)
                                            }}
                                        >
                                            {item?.name}
                                        </li>
                                    )
                                })
                        }
                    </ul>
                </Hidden>
                <Hidden lgUp>
                    <div className={styles.appBarMobile}>
                        <Link to="">
                            <img src="/logo_high.png" alt="/logo_high.png" className={styles.logo}/>
                        </Link>
                        {/* <Button color="inherit">
              PLAY PRIVATE BETA
            </Button> */}
                        <Button onClick={() => setOpenMenuMobile(!openMenuMobile)} className={styles.btnOpenMenu}>
                            <MenuIcon className={styles.iconOpenMenu}/>
                        </Button>
                    </div>
                </Hidden>
            </div>

            <Hidden lgUp>
                <Dialog
                    fullScreen
                    open={openMenuMobile}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseMenuMobile}
                    className={styles.customerDialogMenuMobile}
                >
                    <AppBar className={styles.appBarDialogMenu}>
                        <Toolbar className={styles.toolbarDialogMenu}>
                            <Link to="">
                                <img src="/logo_high.png" alt="/logo_high.png" className={styles.logo}/>
                            </Link>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseMenuMobile}
                                aria-label="close"
                            >
                                <CloseIcon className={styles.iconOpenMenu}/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <ul className={styles.menuHeader}>
                            {
                                isPlayPage ?
                                    menuPlay?.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className={styles.itemMenuHeader}
                                                onClick={() => onClickItemMenuPlay(item.link)}
                                            >
                                                {item?.name}
                                            </li>
                                        )
                                    })
                                    :
                                    isMarketPage ?
                                        menuMarket?.map((item, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={styles.itemMenuHeader}
                                                    onClick={() => onClickItemMenuPlay(item.link)}
                                                >
                                                    {item?.name}
                                                </li>
                                            )
                                        })
                                        :
                                        menu?.map((item, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={styles.itemMenuHeader}
                                                    onClick={() => onClickMenuMobile(item.link)}
                                                >
                                                    {item?.name}
                                                </li>
                                            )
                                        })
                            }
                        </ul>
                    </DialogContent>
                </Dialog>
            </Hidden>
        </header>
    );
};

export default Header;