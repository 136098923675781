import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    ourTeamComponent: {
      position: "relative",
        marginTop: 250,
        marginBottom: 150,
    },

    listTems: {
      width: 1600,
      maxWidth: "94%",
      margin: "auto",
      display: "grid",
      gridTemplateColumns: "repeat(4, 25%)",
      marginTop: 45,
      paddingLeft: 15,
      paddingRight: 15,

      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(3, 33.33%)",
      },

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(2, 50%)",
      },
    },

    boxItem: {
      textAlign: "center",
      marginBottom: 15,
    },

    boxAvt: {
      "& img": {
        width: "auto",
      },
    },

    loadAvt: {
      width: "100%",
      minHeight: 150,
      background: theme?.custom?.colors?.nameOurTeam,
    },

    info: {},

    name: {
      fontSize: 25,
      color: theme?.custom?.colors?.nameOurTeam,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
      marginTop: 0,
      marginBottom: 0,

      [theme.breakpoints.down("sm")]: {
        fontSize: 17,
      },
    },

    des: {
      fontSize: 17,
      color: theme?.custom?.colors?.white,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,

      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
      },
    },

    boxSlider: {
      width: 1575,
      maxWidth: "100%",
      padding: "0px 10%",
      margin: "auto",
      position: "relative",
      marginTop: 70,

      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        marginBottom: 30,
        padding: "0px 20px",
      },
    },

    sliderOurTeamComponent: {
      width: "100%",
      maxWidth: "100%",
      margin: "auto",
      marginTop: 20,
      position: "initial",

      "& .swiper-button-prev": {
        background: "url('/images/buttons/btn_preview.png')",
        backgroundSize: "100% 100%",
        width: 62,
        height: 82,
        transition: "all .1s ease-in",
        left: 0,
        marginTop: "-4%",
        opacity: 0.5,

        [theme.breakpoints.down("xs")]: {
          marginTop: "-8%",
        },

        [theme.breakpoints.down("sm")]: {
          left: "0%",
          width: 20,
          height: 30,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(1px)",
          opacity: 1,
        },
      },

      "& .swiper-button-next": {
        background: "url('/images/buttons/btn_next.png')",
        backgroundSize: "100% 100%",
        width: 62,
        height: 82,
        transition: "all .1s ease-in",
        right: 0,
        marginTop: "-4%",
        opacity: 0.5,

        [theme.breakpoints.down("xs")]: {
          marginTop: "-8%",
        },

        [theme.breakpoints.down("sm")]: {
          right: "0%",
          width: 20,
          height: 30,
        },

        "&:after": {
          display: "none",
        },

        "&:hover": {
          transform: "translateY(2px)",
          opacity: 1,
        },
      },
    },
  };
});

export default useStyles;
