import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
    return {
        bannerComponent: {
            position: "relative",
            backgroundColor: "#FFFFFF",
        },

        contentWidth: {
            maxWidth: 1290,
            paddingTop: 100,
            paddingBottom: 100,
            paddingLeft: 15,
            paddingRight: 15,
            margin: "auto",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 10,
            flex: "1",

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                paddingTop: 30,
                paddingBottom: 30,
            },
        },

        leftComponent: {
            marginRight: 35,
            flex: "0 1 25%",

            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                flex: "1",
            },
        },

        title: {
            marginTop: 0,
            marginBottom: "1.5rem",
            fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
            fontSize: 40,
            color: "#59177a",

            [theme.breakpoints.down("sm")]: {
                fontSize: 26,
            },
        },

        destitle: {
            fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
            fontSize: 16,
            color: "#59177a",
        },

        btnView: {
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            color: "#dd1438",
            fontSize: 18,
            marginBottom: 10,
        },

        navTab: {
            margin: 0,
            padding: 0,
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            paddingLeft: 25,

            "& li": {
                margin: " 0px 8px 0px 0px",
                zIndex: 4,
                position: "relative",
                fontSize: 18,
                lineHeight: "1.4em",
                padding: "10px 20px 10px 20px",
                color: "#8f959d",
                background: "#e2e6e6",
                cursor: "pointer",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
            },

            "& li.active": {
                color: "#81878f",
                background: "#ECE9F0",
            }
        },

        contentTab: {
            background: "#ECE9F0",
            paddingTop: 50,
            paddingBottom: 50,
            display: "block",
            borderRadius: 8,
            width: 900,
            maxWidth: "100%",
        },

        rightComponent: {
            flex: "0 1 100%",
        },

        titleCtTab: {
            marginTop: 0,
            marginBottom: "0.5rem",
            fontSize: 17,
            color: "#1d2a3a",
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
        },

        titleCtTab2: {
            marginTop: "1.5rem",
        },

        listImage: {
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            position: "relative",
            zIndex: 10,

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },

        itemImage: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            zIndex: 1,
            minWidth: 0,
            minHeight: 0,
            flex: "0 1 50%",
            alignSelf: "auto",
            marginRight: 15,

            "&:last-child": {
                marginRight: 0,
            },

            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                marginBottom: 10,
            },
        },

        figureImage: {
            padding: 5,
            transition: "all 0.3s ease",
            margin: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",

            "& img": {
                maxWidht: 400,
                maxHeight: 238,
                height: "auto",
            }
        },

        imageName: {
            marginTop: 20,
            fontSize: 14,
            color: "#8f959d",
            fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
        },

        charSwiper: {
            width: "100%",
            maxWidth: "100%",
            margin: "auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",

            "& .swiper-button-prev": {
                background: "url('/images/buttons/btn_preview.png')",
                backgroundSize: "100% 100%",
                width: 30,
                height: 40,
                transition: "all .1s ease-in",
                left: "1%",
                opacity: 1,
                marginTop: "-3%",

                "&:after": {
                    display: "none",
                },

                "&:hover": {
                    transform: "translateY(1px)",
                    opacity: 1,
                },
            },

            "& .swiper-button-next": {
                background: "url('/images/buttons/btn_next.png')",
                backgroundSize: "100% 100%",
                width: 30,
                height: 40,
                transition: "all .1s ease-in",
                right: "1%",
                opacity: 1,
                marginTop: "-3%",

                "&:after": {
                    display: "none",
                },

                "&:hover": {
                    transform: "translateY(2px)",
                    opacity: 1,
                },
            },
        },

        charImageSlider: {
            textAlign: "center",
            position: "relative",
        },

        bgImageSlider2: {
            position: "relative",
            maxWidth: 800,
        },

    };
});

export default useStyles;
