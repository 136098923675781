import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    mainHeader: {
      position: "fixed",
      width: "100vw",
      zIndex: 99,
    },

    showMainHeader: {
      top: 0,
      transition: "top .5s",
    },

    hiddenMainHeader: {
      top: "-25vw",
      transition: "top .5s",
    },

    boxHeader: {
      background: "url(/images/header/header_nav.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% -16px",
      backgroundSize: "1476px 105px",
      padding: "9px 15px 29px 15px",

      [theme.breakpoints.down("md")]: {
        padding: "9px 15px 9px 15px",
        backgroundSize: "1476px 121px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "9px 15px 5px 15px",
        backgroundPosition: "50% -27px",
      },
    },

    menuHeader: {
      width: 1200,
      maxWidth: "100%",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        marginTop: 70,
      },
    },

    itemMenuHeader: {
      fontSize: 30,
      color: theme?.custom?.colors?.menu,
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      lineHeight: "38px",
      padding: "5px 24px",
      cursor: "pointer",
      transition: "all .1s ease-in",
      [theme.breakpoints.down("lg")]: {
        fontSize: 24,
      },

      [theme.breakpoints.down("md")]: {
        width: "100%",
        textAlign: "center",
        marginTop: 15,
      },

      "&:hover": {
        textShadow: "0 0 1.5rem #ffe788",
        transform: "translateY(1px)",
      },
    },

    logo: {
      height: 60,

      [theme.breakpoints.down("sm")]: {
        height: 50,
      },
    },

    appBarMobile: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    customerDialogMenuMobile: {
      "& .MuiPaper-root": {
        background: "url(/images/bg_texture.png)",
        paddingRight: "0px !important",
      },
    },

    headMobileMenu: {
      alignItems: "center",
      justifyContent: "space-between",
      padding: 15,
      paddingRight: 15,
    },

    appBarDialogMenu: {
      paddingRight: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
    },

    toolbarDialogMenu: {
      justifyContent: "space-between",
    },

    iconOpenMenu: {
      fontSize: 50,
      color: theme?.custom?.colors?.menu,

      [theme.breakpoints.down("sm")]: {
        fontSize: 40,
      },
    },

    btnOpenMenu: {
      width: 50,
      height: 50,
    }
  };
});

export default useStyles;
