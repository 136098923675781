import useStyles from './styles';

const ErrorPage = () => {
  const styles = useStyles();

  return (
    <div className={styles.errorPage}>
      Error!
    </div>
  );
};

export default ErrorPage;