import {useState} from 'react';
import useStyles from './styles';

const nav = ["FULL COLOR", "MONO COLOR"];

const LogoComponent = (props: any) => {
    const styles = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const imgs = [
        [
            "/images/mediakit/logo_full.png",
            "/images/mediakit/logo_full.png",
        ],
        [
            "/images/mediakit/logo_1.png",
            "/images/mediakit/logo_2.png",
        ]
    ];

    const bgColors = ["#ffffff", "#22185e"];

    return (
        <section className={styles.logoComponent}>
            <div className={styles.contentWidth}>
                <div className={styles.leftComponent}>
                    <h2 className={styles.title}>Logo</h2>
                    <h2 className={styles.destitle}>The logo comes in two different lockups, and two different color
                        combinations.</h2>
                </div>
                <div className={styles.rightComponent}>
                    <ul className={styles.navTab}>
                        {
                            nav?.map((item, index) => {
                                return (
                                    <li key={index} className={`${activeTab === index ? "active" : ''}`}
                                        onClick={() => setActiveTab(index)}>{item}</li>
                                )
                            })
                        }
                    </ul>
                    <div className={styles.contentTab}>
                        <div className={styles.listImage}>
                            {
                                imgs[activeTab]?.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.itemImage}
                                             style={{background: bgColors[index]}}>
                                            <figure className={styles.figureImage}>
                                                <img src={item} alt={item}/>
                                            </figure>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LogoComponent;