import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    mainContent: {
      background: "url(/images/bg_texture_full.png)",
      backgroundRepeat: "repeat-y",
      backgroundSize: "100% auto",
      overflow: "hidden"
    },

    overMobile: {

      [theme.breakpoints.down("md")]: {
        overflow: "hidden",
      },
    }
  };
});

export default useStyles;
