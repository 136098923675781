import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    featuresComponent: {
      position: "relative",
      marginTop: -68,
    },

    bgFeaturesComponent: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },

    boxSlide: {
      position: "relative",
      background: "url('/images/features/bg4.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "top center",
      paddingTop: "19%",
      paddingBottom: "12%",
      marginTop: 60,

      [theme.breakpoints.down("lg")]: {
        paddingTop: "20%",
        paddingBottom: "20%",
        backgroundSize: "100% 100%",
        marginTop: 40,
      },

      [theme.breakpoints.down("md")]: {
        paddingTop: "17%",
        paddingBottom: "15%",
        background: "url('/images/features/bg_mobile.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "100% 100%",
        marginTop: 40,
      },

      [theme.breakpoints.down("sm")]: {
        paddingTop: "17%",
        paddingBottom: "15%",
        backgroundPosition: "top center",
        marginTop: 25,
      },

      [theme.breakpoints.down("xs")]: {
        paddingTop: "17%",
        paddingBottom: "15%",
      },
    },

    mySwiperFeatures: {
      width: "100%",
      paddingBottom: "0%",

      "& .swiper-button-prev": {
        background: "url('/images/buttons/btn_preview.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        left: "50%",
        transform: "translateX(-50%)",
        marginLeft: -106,
        bottom: 0,
        top: "auto",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },

        "&:after": {
          display: "none",
        },
      },

      "& .swiper-button-next": {
        background: "url('/images/buttons/btn_next.png')",
        backgroundSize: "100% 100%",
        width: 79,
        height: 108,
        right: "50%",
        transform: "translateX(50%)",
        marginRight: -97,
        bottom: 0,
        top: "auto",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },

        "&:after": {
          display: "none",
        },
      },
    },

    listFeature: {
      width: "59%",
      minWidth: 500,
      maxWidth: "100%",
      margin: "auto",

      [theme.breakpoints.down("lg")]: {
        width: "58%",
        paddingRight: "2%",
      },

      [theme.breakpoints.down("sm")]: {
        width: "80%",
        maxWidth: 500,
        minWidth: "auto",
      },
    },

    itemFeature: {
      display: "grid",
      gridTemplateColumns: "calc(50%) calc(50%)",
      gridTemplateAreas: "'a b'",
      gridGap: "30px",
      marginBottom: 5,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gridGap: 0,
        marginBottom: 30,
      },

      "& h3": {
        fontSize: 45.83,
        color: theme?.custom?.colors?.title,
        fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
        marginTop: 25,
        marginBottom: 20,

        [theme.breakpoints.down("lg")]: {
          fontSize: 30,
          marginBottom: 10,
          marginTop: 10,
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: 22,
          marginBottom: 10,
          marginTop: 10,
        },
      },

      "& span": {
        fontSize: 25,
        color: theme?.custom?.colors?.title,
        fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,
        display: "block",
        position: "relative",
        paddingBottom: 60,

        [theme.breakpoints.down("lg")]: {
          fontSize: 18,
        },

        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },

        "&:after": {
          content: "''",
          background: "url('/images/features/after.png')",
          display: "block",
          position: "absolute",
          width: 334,
          maxWidth: "100%",
          height: 44,
          backgroundSize: "100% 100%",
          left: "50%",
          transform: "translate(-50%, 0)",
          bottom: 0,

          [theme.breakpoints.down("sm")]: {
            width: 200,
            height: 30
          },
        },
      },
    },

    itemFeatureAreas: {
      gridTemplateAreas: "'b a'",
    },

    imgFeature: {
      gridArea: "a",

      "& img": {
        // width: "100%",
      },
    },

    infoFeature: {
      gridArea: "b",
    },

    boxFeaturesSlider: {},
  };
});

export default useStyles;
