import {useEffect, useState} from 'react';
import {isIOS, isSafari} from 'react-device-detect';
import {useLocation} from 'react-router-dom';
import useStyles from './styles';

const FooterComponent = (props: any) => {
    const styles = useStyles();
    const location = useLocation();
    const [navs] = useState([
        {
            name: "About",
            childs: [
                {
                    title: "News",
                    link: "https://news.metamerge.game/",
                },
                {
                    title: "Document",
                    link: "https://docs.metamerge.game",
                },
                {
                    title: "Marketplace",
                    link: "https://marketplace.metamerge.game",
                },
                {
                    title: "Brand Assets",
                    link: "https://metamerge.game/#/brand",
                },
            ]
        },
        {
            name: "Info",
            childs: [
                {
                    title: "Terms of Use",
                    link: "https://docs.metamerge.game/terms-of-use",
                },
                {
                    title: "Privacy Policy",
                    link: "https://docs.metamerge.game/privacy-policy",
                },
                {
                    title: "Risk Disclosure",
                    link: "https://docs.metamerge.game/risk-disclosure",
                },
                /*{
                  title: "Coinmarketcap",
                  link: "#",
                },
                {
                  title: "Coingecko",
                  link: "#",
                },*/
            ]
        },
    ]);

    const [socials] = useState([
        {
            name: "Telegram",
            icon: "/images/footer/Icon_Telegram.png",
            link: "https://t.me/metamerge",
        },
        {
            name: "Twitter",
            icon: "/images/footer/Icon_Twitter.png",
            link: "https://twitter.com/meta_merge",
        },
        {
            name: "Discord",
            icon: "/images/footer/Icon_Discord.png",
            link: "https://discord.io/MetaMergeio",
        },
        {
            name: "Medium",
            icon: "/images/footer/Icon_Medium.png",
            link: "https://medium.com/@metamerge",
        },
        {
            name: "Facebook",
            icon: "/images/footer/Icon_Facebook.png",
            link: "https://www.facebook.com/metamerge/",
        },
        {
            name: "Youtube",
            icon: "/images/footer/Icon_Youtube.png",
            link: "https://www.youtube.com/channel/UCtewLVBjaRSfzn4LL9YHJ-A",
        },
    ]);

    return (
        <footer className={styles.bgFooter}>
            <div className={styles.footerComponent}>
                <div className={styles.logoFooter}>
                    {
                        (isIOS || isSafari) ?
                            <img src="/logo_high.png" alt="/logo_high.png" className={styles.logoWebmFooter}/>
                            :
                            <video src="/webms/logo.webm" autoPlay muted loop className={styles.logoWebmFooter}/>
                    }
                    {/*<img src="/logo_high.png" alt="/logo_high.png" className={styles.logoWebmFooter} />*/}
                </div>
                <div className={styles.contentFooter}>
                    {
                        navs?.map((item, index) => {
                            return (
                                <div className={styles.navFooter + " " + styles.navFooterMobile} key={index}>
                                    <h3 className={styles.nameNavFooter}>{item.name}</h3>
                                    <ul>
                                        {
                                            item.childs?.map((child, i) => {
                                                return (
                                                    <li className={styles.itemNav} key={i}>
                                                        <a href={child.link} target="_blank">{child.title}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                    <div className={styles.navFooter}>
                        <h3 className={styles.nameNavFooter}>Social</h3>
                        <ul>
                            {
                                socials?.map((item, index) => {
                                    return (
                                        <li className={styles.itemNavSocial} key={index}>
                                            <a href={item.link} target="_blank">
                                                <img src={item.icon} alt={item.icon}/>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;