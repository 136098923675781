import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Hidden } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';

import SwiperCore, {
  Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

const OurTeamComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();

  // const [ourTeams] = useState([
  //   {
  //     name: "Tuan Duong - CEO",
  //     des: "Former Game Leader Zego Studio<br/>CEO & Co-founder BBold Studio",
  //     avt: "/images/our_team/tuan.png",

  //     name2: "Anna<br/>Head of Business",
  //     des2: "Former Sales and Account<br/>Manager at SotaTek",
  //     avt2: "/images/our_team/anna.png"
  //   },
  //   {
  //     name: "Angela - COO",
  //     des: "5 years+ in Blockchain industry<br/>Former BDM at SotaTek",
  //     avt: "/images/our_team/angela.png",

  //     name2: "Ngan Pham<br/>Art Lead",
  //     des2: "Art Lead at BBold Studio",
  //     avt2: "/images/our_team/ngan.png"
  //   },
  //   {
  //     name: "Bo Tran - CTO",
  //     des: "Former Game Director at Segu VN<br/>Founder BBold Studio ",
  //     avt: "/images/our_team/bo.png",

  //     name2: "Thao Dang<br/>UI/UX Lead",
  //     des2: "UI/UX Lead at BBold Studio",
  //     avt2: "/images/our_team/thao.png"
  //   },
  //   {
  //     name: "Jane Le - CMO",
  //     des: "Former Marketing Lead<br/>at FPT software",
  //     avt: "/images/our_team/phuong.png",

  //     name2: "Hung Duy<br/>Game Server Lead",
  //     des2: "Former CEO & Co-founder<br/>iMobi Technology ",
  //     avt2: "/images/our_team/hung.png"
  //   },
  //   {
  //     name: "Hoc Do - Game Dev",
  //     des: "5 years+ working in Game industry<br/>Unity Developer at BBold Studio",
  //     avt: "/images/our_team/hoc.png",

  //     name2: "Phuc<br/>Blockchain Lead",
  //     des2: "4 years+ Blockchain Lead<br/>at SotaTek",
  //     avt2: "/images/our_team/phuc.png",
  //   },
  // ])

  return (
    <div className={styles.ourTeamComponent}>
      <TitleLanding title="INVESTORS" />
      <Hidden mdDown>
        <div className={styles.boxSlider}>
          <Swiper
            navigation={true}
            slidesPerView={4}
            spaceBetween={0}
            loop={true}
            pagination={{
              "clickable": true
            }}
            breakpoints={{
              "@0.00": {
                "slidesPerView": 3,
                "spaceBetween": 0,
              },
              "@0.75": {
                "slidesPerView": 4,
                "spaceBetween": 0
              },
              "@1.00": {
                "slidesPerView": 4,
                "spaceBetween": 0
              },
              "@1.50": {
                "slidesPerView": 4,
                "spaceBetween": 0
              }
            }}
            className={styles.sliderOurTeamComponent}>
            {
              <div className={styles.boxItem} onClick={() => {
                window.open('https://kyber.network/', '_blank');
              }}>
                <div className={styles.boxAvt}>
                  {
                    loading ?
                      <Skeleton variant="rect" className={styles.loadAvt}>
                        <div style={{ paddingTop: '57%' }} />
                      </Skeleton>
                      : <img width={350} height={350} src={'/images/partners/kyber.png'} alt={'/images/partners/kyber.png'} />
                  }
                </div>
                <div className={styles.info}>
                  <h3 className={styles.name}>Kyber Ventures</h3>
                  {/* <div className={styles.des}><span dangerouslySetInnerHTML={{ __html: item.des }}></span></div> */}
                </div>
              </div>
              // ourTeams?.map((item, index) => {
              //   return (
              //     <SwiperSlide key={index}>
              //       <div className={styles.boxItem} >
              //         <div className={styles.boxAvt}>
              //           {
              //             loading ?
              //               <Skeleton variant="rect" className={styles.loadAvt}>
              //                 <div style={{ paddingTop: '57%' }} />
              //               </Skeleton>
              //               : <img src={item.avt} alt={item.avt} />
              //           }
              //         </div>
              //         <div className={styles.info}>
              //           <h3 className={styles.name}><span dangerouslySetInnerHTML={{ __html: item.name }}></span></h3>
              //           <div className={styles.des}><span dangerouslySetInnerHTML={{ __html: item.des }}></span></div>
              //         </div>
              //       </div>
              //       <div className={styles.boxItem} >
              //         <div className={styles.boxAvt}>
              //           {
              //             loading ?
              //               <Skeleton variant="rect" className={styles.loadAvt}>
              //                 <div style={{ paddingTop: '57%' }} />
              //               </Skeleton>
              //               : <img src={item.avt2} alt={item.avt2} />
              //           }

              //         </div>
              //         <div className={styles.info}>
              //           <h3 className={styles.name}><span dangerouslySetInnerHTML={{ __html: item.name2 }}></span></h3>
              //           <div className={styles.des}><span dangerouslySetInnerHTML={{ __html: item.des2 }}></span></div>
              //         </div>
              //       </div>
              //     </SwiperSlide>
              //   )
              // })
            }
          </Swiper>
        </div>
      </Hidden>

      <Hidden lgUp>
        <div className={styles.boxSlider}>
          <Swiper
            navigation={true}
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            pagination={{
              "clickable": true
            }}
            breakpoints={{
              "@0.00": {
                "slidesPerView": 2,
                "spaceBetween": 0,
              },
              "@0.75": {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              "@1.00": {
                "slidesPerView": 3,
                "spaceBetween": 40
              },
              "@1.50": {
                "slidesPerView": 3,
                "spaceBetween": 50
              }
            }}
            className={styles.sliderOurTeamComponent}>
            {
              <div className={styles.boxItem} onClick={() => {
                  window.open('https://kyber.network/', '_blank');
                }}>
                <div className={styles.boxAvt}>
                  {
                    loading ?
                      <Skeleton variant="rect" className={styles.loadAvt}>
                        <div style={{ paddingTop: '57%' }} />
                      </Skeleton>
                      : <img width={275} height={275} src={'/images/partners/kyber.png'} alt={'/images/partners/kyber.png'} />
                  }
                </div>
                <div className={styles.info}>
                  <h3 className={styles.name}>Kyber Ventures</h3>
                </div>
              </div>
            }
          </Swiper>
        </div>
      </Hidden>
    </div>
  );
};

export default OurTeamComponent;