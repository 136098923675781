import {useEffect, useState} from "react";
import {Hidden} from '@material-ui/core';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TitleLanding2 from '../../../components/TitleLanding2';
import useStyles from './styles';

import SwiperCore, {
    Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

type Partner = {
    link: string,
    img: string
}

const PartnersComponent = (props: any) => {
    const styles = useStyles();
    // const partnersData = fetch('data/partners.json');
    const [partners, setPartners] = useState([] as Partner[]);
    const getData = () => {
        fetch('./images/partners/data.json?id=1313123'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(function (response) {
            return response.json();
        }).then(function (data) {
            console.log(data);
            setPartners(data);
        });
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={styles.partnersComponent}>
            <TitleLanding2 title="PARTNERS" className={styles.titlePartners}/>
            <Hidden mdDown>
                <div className={styles.listPartners}>
                    {
                        partners?.map((item, index) => {
                            return (
                                <div key={index} className={styles.boxPartner}>
                                    <a href={item.link} target="_blank">
                                        <img src={item.img} className={styles.imgPartner} alt={item.img}/>
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </Hidden>
            <Hidden lgUp>
                {/* <img className={styles.partnerAll} src="/images/partners/partners_all.png"
                     alt="/images/partners/partners_all.png"/> */}
                <div className={styles.listPartners}>
                    {
                        partners?.map((item, index) => {
                            return (
                                <div key={index} className={styles.boxPartner} style={{ width: '100px', height: '100px' }}>
                                    <a href={item.link} target="_blank">
                                        <img width={100} height={100} src={item.img} className={styles.imgPartner} alt={item.img}/>
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </Hidden>
        </div>
    );
};

export default PartnersComponent;