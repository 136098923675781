import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    bannerBrandComponent: {
      marginLeft: "calc(50% - 50vw)",
      marginRight: "calc(50% - 50vw)",
      maxWidth: "100vw",
      width: "100vw",
      paddingLeft: 0,
      paddingRight: 0,
      clear: "both",
      // backgroundImage:" url(/images/banners/poster_3.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      overflow: "hidden",
      backgroundColor: "#191b32",
    },

    contentWidth: {
      maxWidth: 1290,
      paddingTop: 150,
      paddingBottom: 50,
      paddingLeft: 15,
      paddingRight: 15,
      minHeight: 450,
      margin: "auto",
      display: "flex",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        minHeight: "auto",
        paddingBottom: 30,
        paddingTop: 130,
      },
    },
    kadenceColumn: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      alignContent: "space-between",
    },

    title: {
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: 60,
      margin: 0,
      color: "#FFFFFF",
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      // textShadow: "0px 0px 10px #ff00fc, 0px 0px 50px #ff00fc, 0px 0px 10px #ff00fc",
      [theme.breakpoints.down("md")]: {
        fontSize: 25,
      },
    },

    logBan: {
      marginBottom: 50,
      maxWidth: "50%",
    },

    logoWebm: {
      width: "32%",
      marginTop: "-1%",
      marginBottom: "2%",
      bottom: 0,

      [theme.breakpoints.down("sm")]: {
        width: "60%",
      },
    },
  };
});

export default useStyles;
