import { createTheme } from "@material-ui/core/styles";
import { ZIndex } from "@material-ui/core/styles/zIndex";
import { ThemeOptions } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/zIndex" {
  interface ZIndex {
    negative: number;
  }
}

declare module "@material-ui/core/styles" {
  interface Theme {
    zIndex: ZIndex;
  }

  interface ThemeOptions {
    custom?: any;
  }
}

export const createLemonadeTheme = (options: ThemeOptions = {}) => {
  return createTheme(options);
};

const themeOptions = {
  colors: {
    menu: "#ffe8a3",
    rmenu: "#59177a",
    white: "#FFFFFF",
    title: "#eafeff",
    titleShadow: "#ff00fc",
    titleShadow2: "#19e3f5",
    txt: "#d9c8a3",
    btn: "#9e0f0f",
    btnGoTop: "#59177a",
    picture: "#9a7e3e",
    nameOurTeam: "#d024f5",
    nameAdvisors: "#ffefaf"
  },
  fontFamily: {
    arialRoundedMT: "ArialRoundedMT",
    arialRoundedMTBold: "ArialRoundedMTBold",
    canisteDemoSemibold: "CanisteDemoSemibold",
    tangoBTRegular1: "TangoBT-Regular_1",
    tangoBTRegular: "TangoBT-Regular",
  },
  fontSize: {
    h1: "57.33px",
    h2: "47.78px",
    h3: "39.81px",
    h4: "33.18px",
    h5: "27.65px",
    h6: "18px",
  },
  bodyText: {
    large: "19.2px",
    regular: "16px",
    small: "13.33px",
    extraSmall: "11.11px",
  },
  width: {
    leftLayout: "138px",
  },
};

const defaultTheme = createLemonadeTheme({
  custom: themeOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export { themeOptions };
export default defaultTheme;
