import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    bgLandingPage: {
      background: "url(/images/background_head.svg) no-repeat",
      backgroundSize: "100%",
    },

    boxBgAnim: {
      position: "relative",
    },

    bgAnim: {
      position: "absolute",
      width: "100%",
      height: "auto",
      top: 180,

      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    btnGoTop: {
      position: "fixed",
      bottom: 15,
      right: 15,
      zIndex: 50,
      background: theme?.custom?.colors?.btnGoTop,
      opacity: 0.5,
      minWidth: "auto",
      width: 40,
      height: 40,

      "&:hover": {
        opacity: 0.9,
        background: theme?.custom?.colors?.titleShadow,
      },
    },

    iconGoTop: {
      color: theme?.custom?.colors?.white,
    },

    menuFpRight: {
      position: "fixed",
      zIndex: 100,
      top: "50%",
      opacity: 1,
      transform: "translateY(-50%)",
      WebkitTransform: "translate3d(0,-50%,0)",
      right: 20,

      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    itemFpRight: {
      display: "block",
      width: 14,
      height: 13,
      margin: 7,
      position: "relative",

      "&:hover > div": {
        transition: "all .1s ease-in-out",
        width: "auto",
        opacity: 1,
        display: "block",
      },
    },

    iconFpRight: {
      display: "block",
      position: "relative",
      zIndex: 1,
      width: "100%",
      height: "100%",
      cursor: "pointer",
      textDecoration: "none",

      "& span": {
        borderRadius: "50%",
        position: "absolute",
        zIndex: 1,
        height: 8,
        width: 8,
        border: 0,
        background: theme?.custom?.colors?.rmenu,
        left: "50%",
        top: "50%",
        margin: "-2px 0 0 -2px",
        transition: "all .1s ease-in-out",
      },

      "&:hover span": {
        height: 12,
        width: 12,
        margin: "-6px 0 0 -6px",
      },
    },

    TooltipFpRight: {
      right: "100%",
      marginRight: 10,
      position: "absolute",
      top: "-2px",
      color: theme?.custom?.colors?.menu,
      fontSize: 14,
      whiteSpace: "nowrap",
      maxWidth: 220,
      overflow: "hidden",
      display: "none",
      opacity: 0,
      width: 0,
      cursor: "pointer",
    },
  };
});

export default useStyles;
