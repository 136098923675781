import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';
import { isIOS, isSafari } from "react-device-detect";
import { Hidden } from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';

const TokenOmicsComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();

  return (
    <div className={styles.tokenOmicsComponent}>
      <TitleLanding className={styles.titleTokenOmics} title="TOKENOMICS" />
      {
        loading ?
          <Skeleton variant="rect" className={styles.bgTokenOmics}>
            <div style={{ paddingTop: '57%' }} />
          </Skeleton>
          : <img className={styles.bgTokenOmics} src="/images/tokenomics/bg1.png" alt="" />
      }

      {
        loading ?
          <Skeleton variant="rect" className={styles.bgWebmTokenOmics}>
            <div style={{ paddingTop: '57%' }} />
          </Skeleton>
          : (
            (isIOS || isSafari) ?
              <img src="/images/tokenomics/bg2.png" alt="/images/tokenomics/bg2.png" className={styles.bgWebmTokenOmics} />
              :
              <video src="/webms/tokenomics1.webm" autoPlay muted loop className={styles.bgWebmTokenOmics} />
          )
      }
      <Hidden mdDown>
        {
          loading ?
            <Skeleton variant="rect" className={styles.bangTokenOmics}>
              <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            : <img className={styles.bangTokenOmics} src="/images/tokenomics/bang_tokenomic1.png" alt="" />
        }
      </Hidden>
      <Hidden lgUp>
        {
          loading ?
            <Skeleton variant="rect" className={styles.bangTokenOmics}>
              <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            : <img className={styles.bangTokenOmics} src="/images/tokenomics/bang_tokenomic2.png" alt="" />
        }
      </Hidden>
    </div>
  );
};

export default TokenOmicsComponent;