import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    unityCanvas: {
      width: 960,
      height: 540,
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      maxWidth: "98vw",
      maxHeight: "calc(98vw * 0.5625)",
      position: "relative",
      // zIndex: 5,

      "& #unity-progress-bar-full": {
        width: "100%",
        height: "100%",
      },
    },

    unityDesktop: {
      position: "relative",
      marginTop: 100,
      marginBottom: 100,
    },

    iconLoading: {
      position: "absolute",
      top: "50%",
      left: "50%",
      // zIndex: 1,
    },

    headNoti: {
      cursor: "pointer",
    },

    alert: {
      zIndex: 999999999,
      position: "fixed",
      top: 15,
      right: 15,
    },
  };
});

export default useStyles;
