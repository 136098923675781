import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    tokenOmicsComponent: {
      position: "relative",
      // minHeight: "175vh",
      marginBottom: 150,

      [theme.breakpoints.down("sm")]: {
        marginTop: 26,
        minHeight: "105vh",
      },
    },

    bgTokenOmics: {
      width: "100%",
      marginTop: "0%",

      [theme.breakpoints.down("lg")]: {
        marginTop: "20%",
        bottom: 0,
      },

      [theme.breakpoints.down("md")]: {
        marginTop: "-4%",
        position: "absolute",
        bottom: 0,
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: "-8%",
        bottom: 0,
      },

      [theme.breakpoints.down("xs")]: {
        marginTop: -28,
        bottom: 0,
      },
    },

    titleTokenOmics: {
      position: "absolute",
      top: 15,
      width: "100%",

      [theme.breakpoints.down("md")]: {
        position: "relative",
        marginBottom: 0,
        top: 0,
      },
    },

    bgWebmTokenOmics: {
      position: "absolute",
      maxWidth: "100%",
      maxHeight: "30%",
      zIndex: 1,
      right: 0,
      top: 30,
    },

    bangTokenOmics: {
      position: "absolute",
      top: "0%",
      left: "50%",
      transform: "translate(-50%, 15%)",
      maxWidth: "80%",
      zIndex: 2,

      [theme.breakpoints.down("lg")]: {
        transform: "translate(-50%, 15%)",
        maxWidth: "80%",
        maxHeight: "100%",
      },

      [theme.breakpoints.down("md")]: {
        position: "relative",
        transform: "translate(-50%, 0%)",
        maxWidth: "100%",
        maxHeight: "100%",
      },
    },
  };
});

export default useStyles;
