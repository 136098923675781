import { Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { isIOS, isSafari, isMacOs } from 'react-device-detect';
import useStyles from './styles';
const urlPlay = process.env.REACT_APP_PUBLIC_SUBDOMAIN

const BannerComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();
  const onClickPlay = () => {
    // window.location.href = `https://play.google.com/store/apps/details?id=nft.metaverse.metamerge`/*`${urlPlay}`*/;
    //   window.open("https://play.google.com/store/apps/details?id=nft.metaverse.metamerge", '_blank').focus();
      Object.assign(document.createElement('a'), {
          target: '_blank',
          href: (isIOS || isSafari || isMacOs) ?`https://apps.apple.com/app/metamerge/id1634440247`:`https://play.google.com/store/apps/details?id=nft.metaverse.metamerge`,
      }).click();
  };

  return (
    <div className={styles.bannerComponent}>
      <div className={styles.boxImageBanner}>
        {
          loading ?
            <Skeleton variant="rect" width="100%" className={styles.imageBanner}>
              <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            : <video src="/webms/banner.mp4" autoPlay muted loop className={styles.imageBanner} />
        }
      </div>
      <div className={styles.bannerFeetWrapper}>
        <Button className={styles.btnPlay}>
          play
        </Button>
      </div>
    </div>
  );
};

export default BannerComponent;