import {useState} from 'react';
import useStyles from './styles';
import {Swiper, SwiperSlide} from "swiper/react";
import {Skeleton} from "@material-ui/lab";
import {isIOS, isSafari} from "react-device-detect";

const nav = ["PNG"];

const BannerComponent = (props: any) => {
    const styles = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const imgs = [
        [
            "/images/mediakit/Banner_2.png",
            "/images/mediakit/Banner_3.png",
            "/images/mediakit/Banner_1.png",
        ]
    ];

    return (
        <section className={styles.bannerComponent}>
            <div className={styles.contentWidth}>
                <div className={styles.leftComponent}>
                    <h2 className={styles.title}>Banner</h2>
                </div>
                <div className={styles.rightComponent}>
                    <ul className={styles.navTab}>
                        {
                            nav?.map((item, index) => {
                                return (
                                    <li key={index} className={`${activeTab === index ? "active" : ''}`}
                                        onClick={() => setActiveTab(index)}>{item}</li>
                                )
                            })
                        }
                    </ul>

                    <div className={styles.contentTab}>
                        {/*<div className={styles.listImage}>
                            {
                                imgs[activeTab]?.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.itemImage}
                                             style={{background: "#ffffff"}}>
                                            <figure className={styles.figureImage}>
                                                <img src={item} alt={item}/>
                                            </figure>
                                        </div>
                                    )
                                })
                            }
                        </div>*/}
                        <Swiper
                            navigation={true}
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            pagination={{
                                "clickable": true
                            }}
                            className={styles.charSwiper}>
                            <SwiperSlide className={styles.charImageSlider}>
                                <img className={styles.bgImageSlider2} src="/images/mediakit/Banner_2.png"
                                     alt="/images/mediakit/Banner_2.png"/>
                            </SwiperSlide>
                            <SwiperSlide className={styles.charImageSlider}>
                                <img className={styles.bgImageSlider2} src="/images/mediakit/Banner_3.png"
                                     alt="/images/mediakit/Banner_3.png"/>
                            </SwiperSlide>
                            <SwiperSlide className={styles.charImageSlider}>
                                <img className={styles.bgImageSlider2} src="/images/mediakit/Banner_1.png"
                                     alt="/images/mediakit/Banner_1.png"/>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerComponent;