import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    introComponent: {
      // backgroundColor: "#F2F5F5",
      backgroundColor: "#FFFFFF",
      border: "0 solid transparent",
      position: "relative",
    },

    rowLayoutOverlay: {
      // backgroundImage: "url(images/dots4.png)",
      backgroundColor: "#FFFFFF",
      mixBlendMode: "multiply",
      backgroundSize: "auto",
      backgroundPosition: "center center",
      backgroundAttachment: "scroll",
      backgroundRepeat: "repeat",
      top: 0,
      left: 0,
      position: "absolute",
      opacity: 0.3,
      height: "100%",
      width: "100%",
      zIndex: 0,
      filter: "opacity(100%)",
    },

    contentWidth: {
      maxWidth: 1290,
      paddingTop: 70,
      paddingBottom: 70,
      paddingLeft: 15,
      paddingRight: 15,
      margin: "auto",
      display: "flex",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        minHeight: "auto",
        paddingBottom: 50,
        paddingTop: 130,
      },
    },

    kadenceColumn: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      alignContent: "space-between",
    },

    desTitle: {
      fontWeight: 500,
      fontSize: 30,
      color: "#1d2a3a",
      marginTop: 0,
      marginBottom: "1.5rem",
      paddingRight: "20%",
      paddingLeft: "20%",
      position: "relative",
      fontFamily: theme?.custom?.fontFamily?.ArialRoundedMTBold,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0%",
        paddingLeft: "0%",
        fontSize: 17,
      },
    },

    btnDownload: {
      fontSize: 26,
      position: "relative",
      zIndex: 1,
      overflow: "hidden",
      textTransform: "uppercase",
      padding: "15px 20px 15px 20px",
      boxShadow: "0px 0px 0px -7px rgb(0 0 0 / 0%)",
      display: "inline-block",
      cursor: "pointer",
      textDecoration: "none",
      transition: "all 0.2s ease",
      borderRadius: 10,
      background: "#6D49B4",
      color: theme?.custom?.colors?.white,
      fontFamily: theme?.custom?.fontFamily?.ArialRoundedMTBold,
      marginTop: 0,
      marginBottom: "2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },

      "&:hover": {
        color: theme?.custom?.colors?.white,
        background: "#59177a",
      }
    },

    advancedheading: {
      paddingTop: 20,
      fontSize: 16,
      color: "#353f4a",
    },

    rowSepTtypeLittrii: {
      height: 40,
      position: "absolute",
      bottom: -1,
      left: 0,
      overflow: "hidden",
      right: 0,
      zIndex: 1,
      "& svg": {
        fill: "rgb(255, 255, 255)",
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "100%",
        width: "200%",
        display: "block",
        fontSize: 16,
      }
    }
  };
});

export default useStyles;
