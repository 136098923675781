import { Button } from '@material-ui/core';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import { isIOS, isSafari } from 'react-device-detect';
import TitleLanding from '../../../components/TitleLanding';
import useStyles from './styles';
import { Skeleton } from '@material-ui/lab';

const GameStoryComponent = (props: { loading?: boolean }) => {
  const { loading = false } = props;
  const styles = useStyles();

  return (
    <div className={styles.gameStoryComponent}>

      <TitleLanding title="GAME STORY" />

      <ScrollAnimation animateIn='fadeIn'>
        <div className={styles.desG}>
          Welcome to our magical world, MetaMerge, where the land hidden under the fog and awaiting its own master reigning over.
          MetaMerge is filled with magical creatures, fascinating plants, and legendary characters for you to discover. With a sip of "merge" magic, you can build your own island, explore the beauty of the magical world and earn your rewards.
          With you, our masters, this magical universe will come to be.
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn='fadeIn' className={styles.listBtn}>
        <Button className={styles.btnG}>#Metaverse</Button>
        <Button className={styles.btnG}>#Discovery</Button>
        <Button className={styles.btnG}>#Simulation</Button>
      </ScrollAnimation>

      {/* <div className={styles.boxVideo}>
        {
          loading ?
            <Skeleton variant="rect" className={styles.video}>
              <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            : <ReactPlayer className={styles.video} url="https://www.youtube.com/embed/DrzBv4LqNCs" controls volume={1} />
        }
      </div> */}
    </div>
  );
};

export default GameStoryComponent;