import useStyles from './styles';

const NotFoundPage = () => {
  const styles = useStyles();

  return (
    <div className={styles.notFoundPage}>
      Not Found!
    </div>
  );
};

export default NotFoundPage;