import useStyles from './styles';

const Message = (props: any) => {
  const styles = useStyles();

  return (
    <div className={styles.center + " " + props.className}>
      <h2 className={styles.titleLanding}>
        <span className={styles.titleEffect}>{props.title}</span>
      </h2>
    </div>
  );
};

export default Message;