import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

import DefaultLayout from '../../components/Layout/DefaultLayout';
import BannerComponent from './BannerComponent';
import GameStoryComponent from './GameStoryComponent';
import DownloadComponent from './DownloadComponent';
import ConceptArtComponent from './ConceptArtComponent';
import FeaturesComponent from './FeaturesComponent';
import RoadMapComponent from './RoadMapComponent';
import TokenOmicsComponent from './TokenOmicsComponent';
import OurTeamComponent from './OurTeamComponent';
import AdvisorsComponent from './AdvisorsComponent';
import PartnersComponent from './PartnersComponent';
import FooterComponent from './FooterComponent';

import useStyles from './styles';

const LandingPage = (props: any) => {
  const styles = useStyles();
  const [showScrollTop, setShowScrollTop] = useState(true);
  const refHomeSection = useRef<HTMLInputElement>(null);
  const refDownloadComponent = useRef<HTMLInputElement>(null);
  const refGameStoryComponent = useRef<HTMLInputElement>(null);
  const refConceptArtComponent = useRef<HTMLInputElement>(null);
  const refFeaturesComponent = useRef<HTMLInputElement>(null);
  const refRoadMapComponent = useRef<HTMLInputElement>(null);
  const refTokenOmicsComponent = useRef<HTMLInputElement>(null);
  const refOurTeamComponent = useRef<HTMLInputElement>(null);
  const refAdvisorsComponent = useRef<HTMLInputElement>(null);
  const refPartnersComponent = useRef<HTMLInputElement>(null);
  const refFooterComponent = useRef<HTMLInputElement>(null);

  window.addEventListener('scroll', () => handleScroll(), true);

  const handleScroll = () => {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false)
    }
  };


  const onGotoSection = (value: string) => {
    switch (value) {
      case 'HomeSection':
        refHomeSection?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'DownloadComponent':
          refDownloadComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'GameStoryComponent':
        refGameStoryComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'ConceptArtComponent':
        refConceptArtComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'FeaturesComponent':
        refFeaturesComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'RoadMapComponent':
        refRoadMapComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'TokenOmicsComponent':
        refTokenOmicsComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'OurTeamComponent':
        refOurTeamComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'AdvisorsComponent':
        refAdvisorsComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'PartnersComponent':
        refPartnersComponent?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'FooterComponent':
        refFooterComponent?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <DefaultLayout onGotoSection={(value: string) => onGotoSection(value)}>

      <ul className={styles.menuFpRight}>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("HomeSection")}><span></span></span>
          <div className={styles.TooltipFpRight}>Home</div>
        </li>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("DownloadComponent")}><span></span></span>
          <div className={styles.TooltipFpRight}>Play</div>
        </li>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("FeaturesComponent")}><span></span></span>
          <div className={styles.TooltipFpRight}>Features</div>
        </li>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("RoadMapComponent")}><span></span></span>
          <div className={styles.TooltipFpRight}>Roadmap</div>
        </li>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("TokenOmicsComponent")}><span></span></span>
          <div className={styles.TooltipFpRight}>Tokenomics</div>
        </li>
        <li className={styles.itemFpRight}>
          <span className={styles.iconFpRight} onClick={() => onGotoSection("OurTeamComponent")}><span></span></span>
          <div className={styles.TooltipFpRight}>Team</div>
        </li>
      </ul>

      <div ref={refHomeSection}></div>
      <BannerComponent />

      <div ref={refDownloadComponent}></div>
      <DownloadComponent />

      <div ref={refGameStoryComponent}></div>
      <GameStoryComponent />

      <div ref={refConceptArtComponent}></div>
      <ConceptArtComponent />

      <div ref={refFeaturesComponent}></div>
      <FeaturesComponent />

      <div ref={refRoadMapComponent}></div>
      <RoadMapComponent />

      <div ref={refTokenOmicsComponent}></div>
      <TokenOmicsComponent />

      <div ref={refOurTeamComponent}></div>
      <OurTeamComponent />

      {/*<div ref={refAdvisorsComponent}></div>
      <AdvisorsComponent />*/}

      <div ref={refPartnersComponent}></div>
      <PartnersComponent />

      <div ref={refFooterComponent}></div>
      <FooterComponent onGotoSection={(value: string) => onGotoSection(value)} />

      {
        showScrollTop &&
        <Button variant="contained" onClick={() => onGotoSection("HomeSection")} className={styles.btnGoTop}>
          <VerticalAlignTopIcon className={styles.iconGoTop} />
        </Button>
      }
    </DefaultLayout>
  );
};

export default LandingPage;