import {Link} from 'react-router-dom';
import useStyles from './styles';

const IntroComponent = (props: any) => {
    const styles = useStyles();

    return (
        <section className={styles.introComponent}>
            <div className={styles.rowLayoutOverlay}></div>
            <div className={styles.contentWidth}>
                <div className={styles.kadenceColumn}>
                    <h4 className={styles.desTitle}>Download the complete MetaMerge Brand Kit here—which includes logos,
                        backgrounds, and other brand assets. </h4>
                    <a target="_blank" href={"https://drive.google.com/drive/folders/199Vf6dzis-HiX1JNkjubP4eFSZBcqdpj?usp=sharing"} className={styles.btnDownload}>download brand kit</a>
                </div>
            </div>
            <div className={styles.rowSepTtypeLittrii}>
                <svg style={{fill: "#ffffff"}} viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path d="M1000,100l-1000,0l0,-100l475,0l25,98l25,-98l475,0l0,100Z"></path>
                </svg>
            </div>
        </section>
    );
};

export default IntroComponent;