import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    roadMapComponent: {
      position: "relative",
      paddingTop: 100,
      paddingBottom: "18%",

      [theme.breakpoints.down("sm")]: {
        paddingBottom: "24%",
      },
    },

    bgWebm1: {
      position: "absolute",
      maxWidth: "80%",
      zIndex: 1,
      right: -10,
      top: "-5%",

      [theme.breakpoints.down("md")]: {
        maxWidth: "40%",
        top: "0%",
      },
    },

    bgWebm1Img: {
      position: "absolute",
      maxWidth: "80%",
      zIndex: 1,
      right: -10,
      top: "-5%",

      [theme.breakpoints.down("md")]: {
        maxWidth: "23%",
        top: "0%",
      },
    },

    bgWebmroadMap2: {
      position: "absolute",
      maxWidth: "100%",
      zIndex: 1,
      left: 0,
      bottom: "40%",

      [theme.breakpoints.down("md")]: {
        maxWidth: "29%",
        top: "23%",
        bottom: "auto",
      },
    },

    bgRoadMapComponent: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,

      [theme.breakpoints.down("md")]: {
        width: "auto",
        height: "100%",
        maxWidth: "inherit",
      },

      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "115%",
        maxWidth: "inherit",
        bottom: 0,
        top: "auto",
      },
    },

    bgRoadMapComponent2: {
      position: "absolute",
      width: "100%",
      height: "auto",
      bottom: 0,
      left: 0,
      zIndex: 1,

      [theme.breakpoints.down("sm")]: {
        bottom: 8,
        zIndex: 1,
      },
    },

    contentRoadMap: {
      width: "63%",
      margin: "auto",
      marginTop: 80,
      color: theme?.custom?.colors?.white,
      position: "relative",
      zIndex: 3,

      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingLeft: 15,
        paddingRight: 15,
      },

      [theme.breakpoints.down("sm")]: {
        width: "400px !important",
        maxWidth: "100% !important",
        marginTop: 50,
      },

      "&:before": {
        content: "''",
        display: "block",
        height: "100%",
        width: 4,
        background: "#ff00ea",
        position: "absolute",
        top: 0,
        left: "50%",

        [theme.breakpoints.down("md")]: {
          width: 2,
        },

        [theme.breakpoints.down("sm")]: {
          left: 27,
        },
      },
    },

    listRoadMap: {
      display: "flex",
      flexDirection: "column",
    },

    itemRoadMap: {
      width: "50%",
      marginLeft: "auto",
      paddingLeft: "10%",
      position: "relative",

      [theme.breakpoints.down("md")]: {
        paddingTop: 15,
        paddingLeft: 40,
      },

      [theme.breakpoints.down("sm")]: {
        margin: "0px !important",
        marginTop: "0px !important",
        textAlign: "left !important",
        paddingLeft: "50px !important",
        paddingRight: "0px !important",
        width: "100%",
      },

      "&:nth-child(2)": {
        marginTop: -18,
      },

      "&:nth-child(3)": {
        marginTop: -100,
      },

      "&:nth-child(4)": {
        marginTop: -90,
      },

      "&:nth-child(5)": {
        marginTop: -50,
      },

      "&:nth-child(6)": {
        marginTop: -50,
      },

      "&:nth-child(7)": {
        marginTop: -50,
        paddingBottom: 50,
      },

      "&:before": {
        content: "''",
        display: "block",
        position: "absolute",
        top: 10,
        left: -32,
        background: "url('/images/icon_map.png')",
        backgroundSize: "100% 100%",
        width: 127,
        height: 67,

        [theme.breakpoints.down("md")]: {
          top: 10,
          left: -13,
          width: 48,
          height: 28,
        },

        [theme.breakpoints.down("sm")]: {
          left: "0px !important",
          transform: "rotate(0deg) !important",
          top: 15,
        },
      },
    },

    itemRoadMapOll: {
      marginLeft: 0,
      paddingLeft: 0,
      paddingRight: "10%",
      textAlign: "right",

      [theme.breakpoints.down("md")]: {
        paddingTop: 15,
        paddingRight: 40,
      },

      "&:before": {
        transform: "rotate(180deg)",
        left: "auto",
        right: -36,

        [theme.breakpoints.down("md")]: {
          top: 15,
          left: "auto",
          right: -13,
        },
      },
    },

    nameRoadMap: {
      fontSize: 30,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
      marginTop: 25,
      marginBottom: 25,

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        marginBottom: 7,
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: 2,
      },
    },

    infoRoadMap: {},

    time: {
      fontSize: 20.83,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
      marginRight: 5,

      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },

    des: {
      fontSize: 20.83,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,

      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },

    itemList: {
      fontSize: 19,
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,

      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },

    bgWebmKhoi: {
      width: "78%",
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-51.5%, -5%)",

      [theme.breakpoints.down("sm")]: {
        // position: "relative",
        width: "150%",
        marginTop: 50,
        transform: "translate(-50%, 2%)",
      },
    },

    bgWebmKhoiImg: {
      width: "54%",
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, -5%)",

      [theme.breakpoints.down("sm")]: {
        // position: "relative",
        width: "90%",
        marginTop: 50,
        transform: "translate(-50%, 2%)",
      },
    },


    khoi: {
      textAlign: "center",
      marginTop: -334,
      position: "relative",
      paddingBottom: "56%",

      [theme.breakpoints.down("lg")]: {
        paddingBottom: "60%",
      },

      [theme.breakpoints.down("md")]: {
        paddingBottom: "70%",
      },

      [theme.breakpoints.down("sm")]: {
        marginTop: -130,
      },

      [theme.breakpoints.down("xs")]: {
        marginTop: -70,
      },
    },
  };
});

export default useStyles;
