import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    fontsComponent: {
      position: "relative",
      backgroundColor: "#FFFFFF",
    },

    contentWidth: {
      maxWidth: 1290,
      paddingTop: 100,
      paddingBottom: 100,
      paddingLeft: 15,
      paddingRight: 15,
      margin: "auto",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      position: "relative",
      zIndex: 10,

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingTop: 30,
        paddingBottom: 30,
      },
    },

    leftComponent: {
      marginRight: 35,
      flex: "0 1 25%",

      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        flex: "1",
      },
    },
    
    rightComponent: {
      flex: "0 1 100%",
    },

    title: {
      marginTop: 0,
      marginBottom: "1.5rem",
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,
      fontSize: 40,
      color: "#59177a",

      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
      },
    },

    btnView: {
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
      color: "#dd1438",
      fontSize: 18,
      marginTop: 20,
      marginBottom: 10,
      display: "inline-block",
    },

    fontB: {
      fontSize: 40,
      color: "#1d2a3a",
      fontFamily: theme?.custom?.fontFamily?.canisteDemoSemibold,

      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
      },
    },

    fontM: {
      fontSize: 40,
      color: "#1d2a3a",
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMT,

      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
      },
    }
  };
});

export default useStyles;
