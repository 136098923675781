import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    center: {
      textAlign: "center",
      position: "relative",
      zIndex: 3,
    },
    titleLanding: {
      fontFamily: theme?.custom?.fontFamily?.arialRoundedMTBold,
      position: "relative",
      display: "inline-block",
      fontSize: 60,
      margin: "17px auto",
      marginBottom: 0,
      textShadow: `0px 0px 10px ${theme?.custom?.colors?.titleShadow}, 0px 0px 50px ${theme?.custom?.colors?.titleShadow}, 0px 0px 10px ${theme?.custom?.colors?.titleShadow}`,
      color: theme?.custom?.colors?.title,

      [theme.breakpoints.down("md")]: {
        fontSize: 40,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        marginBottom: 0,
      },

      "&:before": {
        backgroundImage: "url('/images/titles/before_title.png')",
        backgroundSize: "100% 100%",
        display: "inline-block",
        width: 90,
        height: 20,
        content:'""',
        position: "absolute",
        right: "100%",
        marginRight: 58,
        top: "50%",
        transform: "translate(0, -35%)",

        [theme.breakpoints.down("sm")]: {
          marginRight: 20,
          width: 50,
          height: 11,
        },
      },

      "&:after": {
        backgroundImage: "url('/images/titles/after_title.png')",
        backgroundSize: "100% 100%",
        display: "inline-block",
        width: 90,
        height: 20,
        content:'""',
        position: "absolute",
        left: "100%",
        marginLeft: 58,
        top: "50%",
        transform: "translate(0, -35%)",

        [theme.breakpoints.down("sm")]: {
          marginLeft: 20,
          width: 50,
          height: 11,
        },
      },
    },

    titleEffect: {

      "&:before": {
        backgroundImage: "url('/images/titles/top_title.png')",
        backgroundSize: "100% 100%",
        display: "inline-block",
        width: 628,
        height: 153,
        content:'""',
        position: "absolute",
        right: -26,
        top: -13,
        transform: "translate(0, -35%)",

        [theme.breakpoints.down("sm")]: {
          right: "-66%",
        },
      },
    },
  };
});

export default useStyles;
