import { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import useStyles from './styles';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import BannerBrandComponent from './BannerBrandComponent';
import IntroComponent from './IntroComponent';
import BannerComponent from './BannerComponent';
import LogoComponent from './LogoComponent';
import ColorComponent from './ColorComponent';
import FontsComponent from './FontsComponent';
import FooterComponent from '../LandingPage/FooterComponent';

const BrandPage = () => {
  const styles = useStyles();
  const [showScrollTop, setShowScrollTop] = useState(true);
  const refHomeSection = useRef<HTMLInputElement>(null);
  

  window.addEventListener('scroll', () => handleScroll(), true);

  const handleScroll = () => {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      setShowScrollTop(true)
    } else {
      setShowScrollTop(false)
    }
  };

  const onGotoSection = (value: string) => {
    switch (value) {
      case 'HomeSection':
        refHomeSection?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }

  return (
    <DefaultLayout onGotoSection={(value: string) => onGotoSection(value)}>
      <div ref={refHomeSection}></div>
      <div className={styles.brandPage}>
        <BannerBrandComponent />
        <IntroComponent />
        <LogoComponent />
        <BannerComponent />
        <ColorComponent />
        <FontsComponent />
      </div>

      {
        showScrollTop &&
        <Button variant="contained" onClick={() => onGotoSection("HomeSection")} className={styles.btnGoTop}>
          <VerticalAlignTopIcon className={styles.iconGoTop} />
        </Button>
      }
      
      <FooterComponent />
    </DefaultLayout>
  );
};

export default BrandPage;